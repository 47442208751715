import { batch } from "react-redux";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { Mapped } from "store/reducers/standardReducer";
import { Query } from "utils/hooks";
import * as request from "services/request";
import * as types from "@trolley/common-frontend";
import { computeID, isLoaded } from "./actionUtils";
import { loadPayment } from "./payments";

export interface Dac7TaxCreate {
  paymentId: string;
  recipientId?: string;
  relevantActivities?: number;
  taxAmount?: string;
  taxCurrency?: string;
  feeAmount?: string;
  feeCurrency?: string;
  enteredAmount?: string;
  enteredCurrency?: string;
  externalId?: string;
  taxReportable?: boolean;
  taxProfileId?: string;
  category?: types.PaymentCategory;
  payoutMethod?: types.PaymentTaxesPayoutMethods;
  feesTaxesAmount?: string;
  feesTaxesCurrency?: string;
}

export interface Dac7TaxUpdate {
  relevantActivities?: number;
  taxAmount?: string;
  taxCurrency?: string;
  feeAmount?: string;
  feeCurrency?: string;
  externalId?: string;
  taxReportable?: boolean;
  taxProfileId?: string;
  rateInquiredAt?: string;
  category?: types.PaymentCategory;
  payoutMethod?: types.PaymentTaxesPayoutMethods;
}

export interface DAC7Tax extends types.DAC7Tax {}

export interface DAC7TaxQuery extends Query {
  ids?: string[];
  recipientIds?: string[];
  paymentIds?: string[];
  statuses?: types.PaymentTaxStatus[];
  taxReportable?: boolean;
  categories?: types.PaymentCategory[];
  payoutMethods?: types.PaymentTaxesPayoutMethods[];
  withPayment?: boolean;
}

export function resetDAC7Tax() {
  standardDispatch(OpCode.RESET, "paymentDac7Taxes");
  standardDispatch(OpCode.RESET, "paymentDac7TaxList");
}

export function loadDAC7Tax(query: DAC7TaxQuery, force?: boolean) {
  const id = computeID(query);
  const { paymentDac7TaxList } = store.getState();

  if (force || !isLoaded(paymentDac7TaxList.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "paymentDac7TaxList", { id });
    request
      .POST<types.DAC7TaxListResponse>("/v1/dac7-tax/search", { query })
      .then(({ body }) => {
        batch(() => {
          const mappedDac7Taxes: Mapped<DAC7Tax> = {};
          const ids = body.dac7tax.map((op) => {
            mappedDac7Taxes[op.id] = op;

            return op.id;
          });

          standardDispatch(OpCode.DATA, "paymentDac7TaxList", {
            id,
            data: {
              records: ids,
              meta: body.meta,
            },
          });

          standardDispatch(OpCode.DATA, "paymentDac7Taxes", {
            bulk: mappedDac7Taxes,
          });
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "paymentDac7TaxList", { id, errors });
      });
  }

  return id;
}

export async function createPaymentDac7Tax(data: Dac7TaxCreate) {
  try {
    standardDispatch(OpCode.LOADING, "paymentDac7Taxes");
    const {
      body: { dac7tax },
    } = await request.POST<types.DAC7TaxResponse>("/v1/dac7-tax/create", { query: data });
    batch(async () => {
      standardDispatch(OpCode.DATA, "paymentDac7Taxes", {
        id: dac7tax.id,
        data: dac7tax,
      });
      standardDispatch(OpCode.RESET, "paymentDac7TaxList");
    });

    return dac7tax;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "paymentDac7Taxes", { errors });
    throw errors;
  }
}

export async function updatePaymentDac7Tax(id: string, query: Dac7TaxUpdate) {
  standardDispatch(OpCode.LOADING, "paymentDac7Taxes", { id });

  try {
    const { body } = await request.POST<types.DAC7TaxResponse>("/v1/dac7-tax/update", { query: { ...query, id } });
    standardDispatch(OpCode.DATA, "paymentDac7Taxes", {
      id,
      data: body.dac7tax,
    });

    const paymentId = body.dac7tax.paymentId;
    if (paymentId) {
      loadPayment(paymentId, true);
    }

    return body.dac7tax;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "paymentDac7Taxes", {
      id,
      errors,
    });
    throw errors;
  }
}

export async function deletePaymentDac7Taxes(ids: string[]) {
  try {
    standardDispatch(OpCode.LOADING, "paymentDac7Taxes");
    await request.POST("/v1/dac7-tax/delete", {
      query: {
        ids,
      },
    });

    standardDispatch(OpCode.DELETE, "paymentDac7Taxes", { ids });
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "paymentDac7Taxes", { errors });
    throw errors;
  }
}
