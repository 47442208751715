import { EmailTemplate, EoyTaxReportDeliveryStatusEmail, EoyTaxReportStatus, TaxDeliveryType } from "@trolley/common-frontend";
import { TableColumnsType, Tabs } from "antd";
import BigNumber from "bignumber.js";
import { Box, Container, CopyToClipboard, CurrencyDisplay, DateDisplay, Flyout, Form, Grid, Icon, Status, Table, Text, Timeline } from "components";
import { RecipientDeliveryMethod, RecipientProfile } from "features/recipient";
import { translateDeliveryMethod } from "features/recipient/RecipientDeliveryMethod";
import { TaxProfileDisplay } from "features/taxProfile";
import { UserProfile } from "features/user";
import TaxProfilePreview from "pages/RecipientsPage/Details/TaxProfile/TaxProfilePreview";
import React, { useState } from "react";
import { DAC7EoyTax } from "store/actions/dac7EoyTaxForms";
import { MessageEvent } from "store/actions/messages";
import { useDac7EoyTaxForm } from "store/hooks/dac7EoyTaxForms";
import { useEmailMessages, useLobMessages } from "store/hooks/messages";
import { useRecipient } from "store/hooks/recipients";

const COLSPAN = {
  xs: 24,
  md: 12,
};

interface EarningDataSource {
  id: string;
  tooltip?: string;
  paymentAmount: string;
  paymentCurrency: string | null;
  taxAmount: string;
  taxCurrency: string | null;
  feeAmount: string;
  feeCurrency: string | null;
  feesTaxesAmount: string;
  feesTaxesCurrency: string | null;
  relevantActivities: number;
}

function getColumns(dac7EoyTax: DAC7EoyTax): TableColumnsType<EarningDataSource> {
  const COLUMNS: TableColumnsType<EarningDataSource> = [
    {
      title: "Calendar Quarter",
      align: "center",
      dataIndex: "id",
      render: (id: string, record) => (
        <Text type="label">
          {id}
          {record.tooltip && <Icon.Hint right tooltip={record.tooltip} />}
        </Text>
      ),
    },
    {
      title: "Payment",
      dataIndex: "paymentAmount",
      align: "right",
      render: (paymentAmount: string, data: EarningDataSource) =>
        new BigNumber(paymentAmount).gt(0) && data.paymentCurrency ? <CurrencyDisplay value={paymentAmount} currency={data.paymentCurrency} /> : " - ",
    },
    {
      title: "Tax",
      dataIndex: "taxAmount",
      align: "right",
      render: (taxAmount: string, data: EarningDataSource) =>
        new BigNumber(taxAmount).gt(0) && data.taxCurrency ? <CurrencyDisplay value={taxAmount} currency={data.taxCurrency} /> : " - ",
    },
    {
      title: "Platform Fee",
      dataIndex: "feeAmount",
      align: "right",
      render: (feeAmount: string, data: EarningDataSource) =>
        new BigNumber(feeAmount).gt(0) && data.feeCurrency ? <CurrencyDisplay value={feeAmount} currency={data.feeCurrency} /> : " - ",
    },
    {
      title: "Relevant Activities",
      dataIndex: "relevantActivities",
      align: "right",
      render: (relevantActivities: number) => (relevantActivities > 0 ? relevantActivities : " - "),
    },
  ];

  if (dac7EoyTax.reportToRegion === "AU") {
    COLUMNS.push({
      title: "Fee Taxes",
      dataIndex: "feesTaxesAmount",
      align: "right",
      render: (feesTaxesAmount: string, data: EarningDataSource) =>
        new BigNumber(feesTaxesAmount).gt(0) && data.feesTaxesCurrency ? <CurrencyDisplay value={feesTaxesAmount} currency={data.feesTaxesCurrency} /> : " - ",
    });

    return COLUMNS;
  } else {
    return COLUMNS;
  }
}

function getEarnings(dac7EoyTax: DAC7EoyTax): EarningDataSource[] {
  const data = [
    {
      id: "Q1",
      paymentAmount: dac7EoyTax.equivalentPaymentAmountQ1,
      paymentCurrency: dac7EoyTax.equivalentPaymentCurrency,
      taxAmount: dac7EoyTax.equivalentTaxAmountQ1,
      taxCurrency: dac7EoyTax.equivalentTaxCurrency,
      feeAmount: dac7EoyTax.equivalentPlatformFeeAmountQ1,
      feeCurrency: dac7EoyTax.equivalentPlatformFeeCurrency,
      relevantActivities: dac7EoyTax.relevantActivityCountQ1,
      feesTaxesAmount: dac7EoyTax.equivalentFeesTaxesAmountQ1,
      feesTaxesCurrency: dac7EoyTax.equivalentFeesTaxesCurrency,
    },
    {
      id: "Q2",
      paymentAmount: dac7EoyTax.equivalentPaymentAmountQ2,
      paymentCurrency: dac7EoyTax.equivalentPaymentCurrency,
      taxAmount: dac7EoyTax.equivalentTaxAmountQ2,
      taxCurrency: dac7EoyTax.equivalentTaxCurrency,
      feeAmount: dac7EoyTax.equivalentPlatformFeeAmountQ2,
      feeCurrency: dac7EoyTax.equivalentPlatformFeeCurrency,
      relevantActivities: dac7EoyTax.relevantActivityCountQ2,
      feesTaxesAmount: dac7EoyTax.equivalentFeesTaxesAmountQ2,
      feesTaxesCurrency: dac7EoyTax.equivalentFeesTaxesCurrency,
    },
    {
      id: "Q3",
      paymentAmount: dac7EoyTax.equivalentPaymentAmountQ3,
      paymentCurrency: dac7EoyTax.equivalentPaymentCurrency,
      taxAmount: dac7EoyTax.equivalentTaxAmountQ3,
      taxCurrency: dac7EoyTax.equivalentTaxCurrency,
      feeAmount: dac7EoyTax.equivalentPlatformFeeAmountQ3,
      feeCurrency: dac7EoyTax.equivalentPlatformFeeCurrency,
      relevantActivities: dac7EoyTax.relevantActivityCountQ3,
      feesTaxesAmount: dac7EoyTax.equivalentFeesTaxesAmountQ3,
      feesTaxesCurrency: dac7EoyTax.equivalentFeesTaxesCurrency,
    },
    {
      id: "Q4",
      paymentAmount: dac7EoyTax.equivalentPaymentAmountQ4,
      paymentCurrency: dac7EoyTax.equivalentPaymentCurrency,
      taxAmount: dac7EoyTax.equivalentTaxAmountQ4,
      taxCurrency: dac7EoyTax.equivalentTaxCurrency,
      feeAmount: dac7EoyTax.equivalentPlatformFeeAmountQ4,
      feeCurrency: dac7EoyTax.equivalentPlatformFeeCurrency,
      relevantActivities: dac7EoyTax.relevantActivityCountQ4,
      feesTaxesAmount: dac7EoyTax.equivalentFeesTaxesAmountQ4,
      feesTaxesCurrency: dac7EoyTax.equivalentFeesTaxesCurrency,
    },
    {
      id: "Total",
      paymentAmount: dac7EoyTax.equivalentPaymentAmountTotal,
      paymentCurrency: dac7EoyTax.equivalentPaymentCurrency,
      taxAmount: dac7EoyTax.equivalentTaxAmountTotal,
      taxCurrency: dac7EoyTax.equivalentTaxCurrency,
      feeAmount: dac7EoyTax.equivalentPlatformFeeAmountTotal,
      feeCurrency: dac7EoyTax.equivalentPlatformFeeCurrency,
      relevantActivities: dac7EoyTax.relevantActivityCountTotal,
      feesTaxesAmount: dac7EoyTax.equivalentFeesTaxesAmountTotal,
      feesTaxesCurrency: dac7EoyTax.equivalentFeesTaxesCurrency,
    },
  ];

  return data;
}

function renderEventTimeline(type: string, events: MessageEvent[]) {
  return (
    <Container padding="small">
      <Timeline>
        {events.map((event) => (
          <Timeline.Item key={event.date} dot={<Status size="small" type={event.type} dot />}>
            <Text style={{ lineHeight: "24px", height: "24px" }}>
              {type} {event.type}
            </Text>
            <DateDisplay value={event.date} icon={false} />
          </Timeline.Item>
        ))}
      </Timeline>
    </Container>
  );
}

interface Props {
  dac7EoyTaxId?: string;
  onClose(): void;
}

export default function EarningPreview(props: Props) {
  const { dac7EoyTaxId } = props;
  const { data: dac7EoyTax } = useDac7EoyTaxForm(dac7EoyTaxId);
  const { data: recipient } = useRecipient(dac7EoyTax?.recipientId);
  const { data: emailMessages } = useEmailMessages({ recipientId: dac7EoyTax?.recipientId, relatedItemId: dac7EoyTax?.id });
  const { data: lobMessages } = useLobMessages(dac7EoyTax?.id);
  const [showTaxForm, setShowTaxForm] = useState("");
  const emailMessage = emailMessages.find((m) => m.templateId === EmailTemplate.WL_TAX_STATEMENT_AVAILABLE);
  const lobMessage = lobMessages.find((m) => m.lobGuid);

  return (
    <Flyout visible={!!dac7EoyTax} onClose={props.onClose} title={dac7EoyTax && <Status type={dac7EoyTax.status} right />}>
      {dac7EoyTax && (
        <Form compact>
          <Box padding="small" header="Earnings Details">
            <Grid padding={["small", "none"]}>
              <Grid.Item {...COLSPAN}>
                <Form.Item label="Updated On">
                  <DateDisplay value={dac7EoyTax.updatedAt} />
                </Form.Item>
              </Grid.Item>
              {dac7EoyTax.reviewedBy && (
                <Grid.Item {...COLSPAN}>
                  <Form.Item label="Updated By">
                    <UserProfile userId={dac7EoyTax.reviewedBy} wrap />
                  </Form.Item>
                </Grid.Item>
              )}
            </Grid>
            <Grid padding={["small", "none"]}>
              <Grid.Item {...COLSPAN}>
                <Form.Item label="Statement ID">
                  <CopyToClipboard value={dac7EoyTax.id} />
                </Form.Item>
              </Grid.Item>
              <Grid.Item {...COLSPAN}>
                <Form.Item label="Related Tax Profile">
                  <TaxProfileDisplay
                    showSignedDate
                    taxProfileId={dac7EoyTax?.taxProfileId}
                    onClick={(e) => {
                      setShowTaxForm(dac7EoyTax.taxProfileId as string);
                    }}
                  />
                </Form.Item>
              </Grid.Item>
              {recipient && (
                <Grid.Item {...COLSPAN}>
                  <Form.Item label="Delivery Method">
                    <RecipientDeliveryMethod recipientId={recipient.id} />
                    {dac7EoyTax.deliveryStatusEmail === EoyTaxReportDeliveryStatusEmail.SEND_REQUEST_FAILED_GENERIC && (
                      <Icon.Status type="error" left tooltip="Email Delivery failed" />
                    )}
                  </Form.Item>
                </Grid.Item>
              )}
              {dac7EoyTax.correction > 0 && (
                <Grid.Item {...COLSPAN}>
                  <Form.Item
                    label="Corrected Statement"
                    tooltip="A Corrected Statement is issued when a statement has been updated (i.e. corrected) and re-issued to the recipient. This may happen multiple times to the same recipient’s statement. The number indicates which version of the corrected statement this is."
                  >
                    {dac7EoyTax.correction}
                  </Form.Item>
                </Grid.Item>
              )}

              {[EoyTaxReportStatus.SENT, EoyTaxReportStatus.VOID].includes(dac7EoyTax.status) && (
                <Grid.Item {...COLSPAN}>
                  <Form.Item label="Downloaded by the Recipient" tooltip="The recipient has downloaded the Earnings from the widget or recipient portal.">
                    {dac7EoyTax.downloadedByRecipient ? (
                      <>
                        <Icon.Status type="success" left />
                        Downloaded
                      </>
                    ) : (
                      "No"
                    )}
                  </Form.Item>
                </Grid.Item>
              )}
              <Grid.Item {...COLSPAN}>
                <Form.Item label="Marked as Filed" tooltip="When sent, statements will automatically be marked as E-Filed.">
                  {dac7EoyTax.dac7SubmittedAt ? (
                    <>
                      Marked as E-Filed <DateDisplay value={dac7EoyTax.dac7SubmittedAt} />
                    </>
                  ) : (
                    "Not Filed"
                  )}
                </Form.Item>
              </Grid.Item>
            </Grid>
          </Box>
          <Table rowKey="id" dataSource={getEarnings(dac7EoyTax)} columns={getColumns(dac7EoyTax)} pagination={false} style={{ marginBottom: "16px" }} />
          {recipient && (
            <>
              <Box padding="small" header="Recipient Profile">
                <RecipientProfile recipientId={recipient.id} showEmail showAddress showLink showStatus />
              </Box>
            </>
          )}
          {(emailMessage || lobMessage) && (
            <Box padding="small" header="Notification Event History">
              <Tabs defaultActiveKey={emailMessage ? TaxDeliveryType.E_DELIVERY : TaxDeliveryType.MAIL} tabBarStyle={{ marginBottom: 0 }}>
                {emailMessage && (
                  <Tabs.TabPane tab={translateDeliveryMethod(TaxDeliveryType.E_DELIVERY)} key={TaxDeliveryType.E_DELIVERY}>
                    {renderEventTimeline("Email", emailMessage.events)}
                  </Tabs.TabPane>
                )}
                {lobMessage && (
                  <Tabs.TabPane tab={translateDeliveryMethod(TaxDeliveryType.MAIL)} key={TaxDeliveryType.MAIL}>
                    {renderEventTimeline("Postal Mail", lobMessage.events)}
                  </Tabs.TabPane>
                )}
              </Tabs>
            </Box>
          )}
          <TaxProfilePreview
            taxProfileId={showTaxForm}
            onClose={() => {
              setShowTaxForm("");
            }}
          />
        </Form>
      )}
    </Flyout>
  );
}
