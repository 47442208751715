import { CurrencyCode, SupportedTaxRegions } from "@trolley/common-frontend";
import { MerchantSettings } from "store/actions/merchantSettings";

/**
 * Gets the default reporting currency based on the supported tax regions.
 *
 * @param supportedTaxRegions - An object representing the supported tax regions and their enabled status.
 * @returns The default reporting currency code.
 *
 * If no active tax region is found, the default currency code is EUR.
 * If the active tax region is AU, the currency code is AUD.
 * If the active tax region is NZ, the currency code is NZD.
 * If the active tax region is CA, the currency code is CAD.
 * For any other active tax region, the default currency code is EUR.
 */
export default function getDefaultReportingCurrency(supportedTaxRegions: MerchantSettings["supportedTaxRegions"]): CurrencyCode {
  const selectedActiveTaxRegion = Object.entries(supportedTaxRegions || {}).find(([_, isEnabled]) => isEnabled);

  if (!selectedActiveTaxRegion) {
    return CurrencyCode.EUR;
  }

  const activeTaxRegion = selectedActiveTaxRegion[0] as SupportedTaxRegions;

  switch (activeTaxRegion) {
    case SupportedTaxRegions.AU:
      return CurrencyCode.AUD;
    case SupportedTaxRegions.NZ:
      return CurrencyCode.NZD;
    case SupportedTaxRegions.CA:
      return CurrencyCode.CAD;
    default:
      return CurrencyCode.EUR;
  }
}
