import * as request from "services/request";
import { storageFactory } from "utils/factories";
import { OpCode, standardDispatch } from "store/dispatcher";
import { authToken, getRandomBytes } from "utils/helpers";
import * as types from "@trolley/common-frontend";
import { initLocale } from "utils/context";

export interface StoredLocale {
  locale: types.SupportedLocales;
}

export interface LoginParams {
  email: string;
  password: string;
  tfaType?: string;
  tfaOtp?: string;
}

interface PardotMeta {
  pardotVisitorId?: string;
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmTerm?: string;
  gclid?: string;
  conversionPage?: string;
  formName?: string;
  comments?: string;
  consent?: string;
  conversionIP?: string;
}

export interface RegistrationParams {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  company: string;
  website: string;
  country: string;
  code?: string;
  language?: string;
  terms: boolean;
  pardotVisitorId?: string; // DEPRECATED
  meta?: PardotMeta;
}

export function getDeviceId() {
  const storage = storageFactory(() => localStorage);

  let deviceId = storage.getItem("deviceId");
  if (deviceId) {
    return deviceId;
  }

  const array = getRandomBytes(20);

  deviceId = btoa(String.fromCharCode.apply(null, array));
  storage.setItem("deviceId", deviceId);

  return deviceId;
}

export function changeLocale(locale: types.SupportedLocales) {
  standardDispatch(OpCode.LOADING, "locale");
  initLocale(locale)
    .then(() => {
      standardDispatch(OpCode.DATA, "locale", {
        data: {
          locale,
        },
      });
    })
    .catch((errors) => {
      standardDispatch(OpCode.ERROR, "locale", { errors });
      throw errors;
    });
}

export async function authRegister(params: RegistrationParams) {
  try {
    standardDispatch(OpCode.LOADING, "user");
    const res = await request.POST<types.Merchant.Result>("/auth/register", { query: params, noToken: true });
    standardDispatch(OpCode.LOADING, "user", { loading: false });

    return res.body;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "user", { errors });
    throw errors;
  }
}

export async function authForgot(email: string) {
  try {
    standardDispatch(OpCode.LOADING, "user");
    await request.POST("/auth/request-password-reset", { query: { email }, noToken: true });
    standardDispatch(OpCode.LOADING, "user", { loading: false });
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "user", { errors });
    throw errors;
  }
}

async function resetPassword(query: { userId: string; code: string; password: string; terms?: boolean }) {
  try {
    standardDispatch(OpCode.LOADING, "user");
    await request.POST("/auth/reset-password", { query, noToken: true });
    standardDispatch(OpCode.LOADING, "user", { loading: false });
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "user", { errors });
    throw errors;
  }
}

export async function authResetPassword(userId: string, code: string, password: string) {
  await resetPassword({ userId, code, password, terms: true });
}

export async function authAcceptInvite(userId: string, code: string, password: string, terms: boolean) {
  await resetPassword({ userId, code, password, terms });
}

export async function authVerifyEmail(userId: string, code: string) {
  try {
    standardDispatch(OpCode.LOADING, "user");
    const res = await request.POST<types.Auth.VerifyResult>("/auth/verify", { query: { userId, code }, noToken: true });
    standardDispatch(OpCode.LOADING, "user", { loading: false });

    return res.body.merchant;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "user", { errors });
    throw errors;
  }
}

export async function authLogin(params: LoginParams) {
  const deviceId = getDeviceId();
  const query: Record<string, string | undefined> = { deviceId, ...params };

  try {
    standardDispatch(OpCode.LOADING, "user");
    const { body } = await request.POST<types.Auth.AuthResult>("/auth/authenticate", { query, noToken: true });

    if (body.authStatus === "token") {
      authToken.set(body.token);

      standardDispatch(OpCode.DATA, "user", {
        data: body.user,
      });

      return { status: body.authStatus };
    }

    standardDispatch(OpCode.LOADING, "user", { loading: false });

    if (body.authStatus === "tfa_required") {
      return { status: body.authStatus, tfaType: body.tfaType, challenge: body.challenge };
    }

    return { status: body.authStatus };
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "user", { errors });
    throw errors;
  }
}
