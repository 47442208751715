import { CountryCode, countryHasPostalCode } from "@trolley/common-frontend";
import { getAlphaColor } from "antd/lib/theme/themes/default/colorAlgorithm";
import { RcFile } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import BigNumber from "bignumber.js";
import {
  ButtonDelete,
  Divider,
  Form,
  Grid,
  Heading,
  Icon,
  Input,
  InputDate,
  InputMask,
  InputNumber,
  InputPhone,
  Radio,
  Select,
  SelectCountry,
  SelectRegion,
  Space,
  UploadDragger,
  phoneNumberValidator,
} from "components";
import { downloadPOSTFile } from "components/FileDownload";
import { validateFutureDate, validatePastDate } from "components/Form/InputDate";
import { getTaxIdLabel } from "pages/BusinessProfile/CompanyDetails";
import { getIdTypes, mapToUploadFile } from "pages/BusinessProfile/variables";
import { isRegionInCountryAsyncValidator } from "pages/RecipientsPage/Details/Info";
import React, { useEffect } from "react";
import { notifyError, notifySuccess } from "store/actions/notifications";
import {
  OnboardingPerson,
  OnboardingPersonUpdate,
  deleteOnboardingPerson,
  removeOnboardingPersonFile,
  updateOnboardingPerson,
  uploadOnboardingPersonFile,
} from "store/actions/onboardingPersons";
import { useOnboarding } from "store/hooks/onboarding";
import css, { createUseStyle } from "style/classname";
import { createFormData, handleFormErrors, omitMaskedValues, pickDiff, translateRegionByCountry } from "utils/helpers";

enum UploadFileType {
  POR = "por", // proof of residence
  SID = "sid", // gov provided ID
}
export enum PersonFormField {
  DIRECTORS = "directors",
  OWNERS = "owners",
}

type OnboardingPersonFormFields = {
  type?: "business" | "individual";
  name?: string;
  firstName?: string;
  lastName?: string;
  title?: string;
  phone?: string;
  address?: string;
  city?: string;
  country?: CountryCode;
  region?: string;
  zip?: string;
  email?: string;
  dateOfBirth?: string;
  personalTaxId?: string;
  nationalities?: CountryCode[];
  govIdType?: string;
  govIdCountry?: CountryCode;
  govIdState?: string;
  govIdIssuingAuthority?: string;
  govIdDocNum?: string;
  govIdIssue?: string;
  govIdExpires?: string;
  percentOfOwnership?: string;
  ownershipType?: string;
  businessTaxId?: string;
  dateFormed?: string;
  isContact?: boolean;
  isPEP?: boolean;

  porUploads?: {
    uid: string;
    url: string;
    name: string;
  }[];
  sidUploads?: {
    uid: string;
    url: string;
    name: string;
  }[];
};

function getFiles(onboardingPerson: OnboardingPerson, type: UploadFileType): UploadFile[] {
  return Object.values(onboardingPerson.files || {})
    .filter((file) => file.type === type)
    .map(mapToUploadFile);
}

interface Props {
  person: OnboardingPerson;
  personLabel: string;
  onboardingType: PersonFormField;
  noOwnership?: boolean;
  onClose(): void;
}

export default function PersonForm(props: Props) {
  const { person: onboardingPerson, personLabel, onboardingType, noOwnership, onClose } = props;
  const { status } = useOnboarding();
  const [form] = Form.useForm<OnboardingPersonFormFields>();
  const type = Form.useWatch("type", form);
  const isOwner = Form.useWatch("isOwner", form);
  const percentOfOwnership = Form.useWatch("percentOfOwnership", form);
  const countryOfResidence = Form.useWatch("country", form);
  const countryOfIssue = Form.useWatch("govIdCountry", form);
  const nationalities: CountryCode[] = Form.useWatch("nationalities", form) ?? [];
  const sidUploads: UploadFile[] | undefined = Form.useWatch("sidUploads", form);
  const styledRadio = useRadioStyle();

  useEffect(() => {
    form.resetFields(["porUploads", "sidUploads"]);
  }, [Object.keys(onboardingPerson.files).join("")]);

  async function onRemoveDocument(personId: string, id: string, docType: UploadFileType) {
    await removeOnboardingPersonFile(personId, id);
  }

  // to be called when uploading files
  async function saveProgress() {
    const values = form.getFieldsValue(true) as Partial<OnboardingPerson>;
    if (onboardingPerson) {
      const dataDiff = pickDiff(omitMaskedValues(values), onboardingPerson);
      if (dataDiff) {
        try {
          await updateOnboardingPerson(onboardingPerson.id, dataDiff as OnboardingPersonUpdate);
        } catch (errors) {
          handleFormErrors(errors, form, "Saving form failed");
          throw errors;
        }
      }
    }
  }

  return (
    <Form
      name={onboardingPerson.id}
      form={form}
      validateTrigger={["onChange", "onSubmit"]}
      initialValues={{
        ...onboardingPerson,
        nationalities: onboardingPerson.nationalities?.filter((v) => !!v),
        porUploads: getFiles(onboardingPerson, UploadFileType.POR),
        sidUploads: getFiles(onboardingPerson, UploadFileType.SID),
      }}
      onFinish={async (values: Partial<OnboardingPerson>) => {
        if (onboardingPerson) {
          const dataDiff = pickDiff(omitMaskedValues(values), onboardingPerson);
          if (dataDiff) {
            try {
              await updateOnboardingPerson(onboardingPerson.id, dataDiff as OnboardingPersonUpdate);
              notifySuccess("Information Saved");
              form.resetFields();
              onClose();
            } catch (errors) {
              handleFormErrors(errors, form, "Saving failed");
              throw errors;
            }
          } else {
            onClose();
          }
        }
      }}
    >
      {onboardingType === PersonFormField.OWNERS ? (
        <Form.Item label="Owner Type" name="type" rules={[{ required: true, message: "Type is required" }]}>
          <Radio.Group
            className={styledRadio}
            direction="horizontal"
            options={[
              {
                value: "business",
                label: (
                  <Space wrap={false} justify="flex-start" align="center" gap="middle">
                    <div className="icon-wrapper">
                      <Icon type="building" theme="solid" />
                    </div>
                    Business
                  </Space>
                ),
              },
              {
                value: "individual",
                label: (
                  <Space wrap={false} justify="flex-start" align="center" gap="middle">
                    <div className="icon-wrapper">
                      <Icon type="user" theme="solid" />
                    </div>
                    Individual
                  </Space>
                ),
              },
            ]}
          />
        </Form.Item>
      ) : (
        <Form.Item name="type" noStyle />
      )}
      {type === "business" && (
        <>
          <Form.Item label="Business Name" name="name" rules={[{ required: true, message: "Business Name is required" }]}>
            <Input name="name" placeholder="Business Name" />
          </Form.Item>
          <Form.Item label="Date Formed" name="dateFormed" rules={[{ required: true, message: "Enter a Date Formed" }, validatePastDate]}>
            <InputDate />
          </Form.Item>
        </>
      )}

      {type === "individual" && (
        <>
          <Form.Item label="First and Middle Names" name="firstName" rules={[{ required: true, message: "First Name is required" }]}>
            <Input name="firstName" placeholder="First Name" />
          </Form.Item>
          <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: "Last Name is required" }]}>
            <Input name="lastName" placeholder="Last Name" />
          </Form.Item>
          <Form.Item label="Position/Title" name="title" rules={[{ required: true, message: "Position/Title is required" }]}>
            <Input name="title" placeholder="CEO" />
          </Form.Item>
        </>
      )}

      {type && (
        <>
          <Form.Item
            label={type === "individual" ? "Phone Number" : "Business Phone Number"}
            name="phone"
            rules={[{ required: true, message: "Phone Number is required" }, phoneNumberValidator()]}
          >
            <InputPhone name="phone" />
          </Form.Item>
          <Form.Item
            label={type === "individual" ? "Email Address" : "Business Email Address"}
            name="email"
            rules={[
              { required: true, message: "Email Address is required" },
              { type: "email", message: "Enter a valid email address" },
            ]}
          >
            <Input name="email" type="email" />
          </Form.Item>

          <Divider margin="medium" />

          <Form.Item
            label={type === "individual" ? "Country of Residence" : "Business Registration Country"}
            name="country"
            validateTrigger={["onChange", "onBlur"]}
            rules={[{ required: true, message: "Country is required" }]}
          >
            <SelectCountry type="all" />
          </Form.Item>

          {countryOfResidence && (
            <Grid padding={["medium", "none"]}>
              <Grid.Item xs={24} md={12}>
                <Form.Item label="Street" name="address" rules={[{ required: true, message: "Address is required" }]}>
                  <Input name="address" placeholder="Address" />
                </Form.Item>
              </Grid.Item>
              <Grid.Item xs={24} md={12}>
                <Form.Item label="City" name="city" rules={[{ required: true, message: "City is required" }]}>
                  <Input name="city" placeholder="City" />
                </Form.Item>
              </Grid.Item>
              <Grid.Item xs={24} md={12}>
                <Form.Item
                  label={translateRegionByCountry(countryOfResidence)}
                  name="region"
                  rules={[
                    { required: true, message: `${translateRegionByCountry(countryOfResidence)} is required` },
                    isRegionInCountryAsyncValidator("country"),
                  ]}
                >
                  <SelectRegion country={countryOfResidence} />
                </Form.Item>
              </Grid.Item>
              <Grid.Item xs={24} md={12}>
                <Form.Item label="Postal Code" name="zip" rules={[{ required: countryHasPostalCode(countryOfResidence), message: "Postal Code is required" }]}>
                  <Input name="zip" placeholder="Postal Code" />
                </Form.Item>
              </Grid.Item>
            </Grid>
          )}
        </>
      )}
      {type === "business" && (
        <Form.Item
          label={getTaxIdLabel(countryOfResidence)}
          name="businessTaxId"
          rules={[
            {
              required: true,
              message: `${getTaxIdLabel(countryOfResidence)} is required`,
            },
            {
              min: countryOfResidence !== CountryCode.US ? 3 : 0,
              message: `${getTaxIdLabel(countryOfResidence)} must be at least 3 digits long`,
            },
            {
              max: 40,
              message: `${getTaxIdLabel(countryOfResidence)} must be shorter than 40 digits long`,
            },
          ]}
        >
          <Input name="businessTaxId" />
        </Form.Item>
      )}
      {onboardingType === PersonFormField.DIRECTORS && (
        <>
          <Form.Item
            name="porUploads"
            label="Upload a Copy of a Proof of Residence"
            tooltip="Please provide any recent (no older than 3 months) utility bill, bank or credit card statement, phone bill (mobile or landline), tax bill, or government-issued documentation that shows your residential address and your name."
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }

              return e?.fileList;
            }}
            rules={[
              {
                async validator(rule, uploads) {
                  if (!uploads?.length) {
                    throw "Proof of residence is required";
                  }
                },
              },
            ]}
          >
            <UploadDragger
              customRequest={async (options) => {
                const file = options.file as RcFile;
                await saveProgress();
                try {
                  await uploadOnboardingPersonFile(onboardingPerson.id, UploadFileType.POR, createFormData([file]));
                  notifySuccess("Document Uploaded");
                } catch (err) {
                  options.onError?.(err);
                }
              }}
              onRemove={async (file) => {
                if (file.uid) {
                  try {
                    await onRemoveDocument(onboardingPerson.id, file.uid, UploadFileType.POR);
                    notifySuccess("Document removed");
                  } catch (errors) {
                    notifyError("Uploaded Document failed");

                    return false;
                  }
                }

                return true;
              }}
              onDownload={async (file) => {
                try {
                  await downloadPOSTFile("/v1/onboarding/persons/document/download", { personId: onboardingPerson.id, uploadId: file.uid }, file.name);
                } catch {
                  notifyError("Download failed");
                }
              }}
            />
          </Form.Item>
        </>
      )}
      {type === "individual" && (
        <>
          <Form.Item label="Date of Birth" name="dateOfBirth" rules={[{ required: true, message: "Enter a Date of Birth" }, validatePastDate]}>
            <InputDate />
          </Form.Item>
          <Form.Item
            label="Nationalities"
            name="nationalities"
            rules={[
              {
                async validator(rule, values) {
                  if (!values.filter((v: string) => v).length) {
                    throw "Nationality is required";
                  }
                },
              },
            ]}
          >
            <SelectCountry type="all" mode="multiple" />
          </Form.Item>
          {[countryOfResidence, ...nationalities].includes(CountryCode.US) && (
            <Form.Item
              label="SSN/ITIN"
              tooltip="Social Security Number or Individual Tax Identification Number"
              name="personalTaxId"
              rules={[
                { required: true, message: "SSN/ITIN is required" },
                {
                  async validator(rule: any, value: string) {
                    if (value && value.replace(/[^\d*]/g, "").length < 9) {
                      throw "SSN/ITIN must be 9 digits long";
                    }
                  },
                },
              ]}
            >
              <InputMask
                formatChars={{
                  "9": "[0-9*]", // must accept "*" masked char
                }}
                mask="999-99-9999"
                name="personalTaxId"
              />
            </Form.Item>
          )}
          {![countryOfResidence, ...nationalities].includes(CountryCode.US) && (
            <Form.Item
              label="Tax Identification Number"
              tooltip="Tax identification number for your country of residence"
              name="personalTaxId"
              rules={[
                { required: true, message: "Tax identification number is required" },
                {
                  async validator(rule: any, value: string) {
                    if (value && (value.length < 9 || value.length > 20)) {
                      throw "Tax identification number must be between 9 and 20 characters long";
                    }
                  },
                },
              ]}
            >
              <Input name="personalTaxId" />
            </Form.Item>
          )}
          <Form.Item
            label="Is this person a domestic or foreign PEP?"
            tooltip={
              <>
                A politically exposed person (PEP) is{" "}
                <ol type="a">
                  <li>an individual who holds a prominent public position or</li>
                  <li>is a close associate/is related to someone who does.</li>
                </ol>
              </>
            }
            name="isPEP"
            rules={[{ required: true, message: "PEP Declaration is required" }]}
            data-testid="isPEP"
          >
            <Radio.Group
              options={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
            />
          </Form.Item>
        </>
      )}
      {!noOwnership && onboardingType !== PersonFormField.OWNERS && (
        <Form.Item label="Does this person own 25% or more of the company?" name="isOwner">
          <Radio.Group
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
          />
        </Form.Item>
      )}

      {isOwner && (
        <>
          <Form.Item
            label="Shareholding Amount (%)"
            name="percentOfOwnership"
            rules={[
              { required: true, message: "Shareholding Amount (%) is required" },
              {
                async validator(rule, value) {
                  const percent = new BigNumber(value);
                  if (percent.isNaN() || percent.isLessThan(0) || percent.isGreaterThan(100)) {
                    throw "Enter a valid percentage";
                  }
                },
              },
              {
                warningOnly: true,
                async validator(rule, value) {
                  const percent = new BigNumber(value);
                  if (percent.isLessThan(25)) {
                    throw "We only require the information of owners owning 25% or more. Please ensure that at least one of these owners is specified.";
                  }
                },
              },
            ]}
          >
            <InputNumber inputMode="decimal" name="percentOfOwnership" placeholder="25" />
          </Form.Item>
          <Form.Item label="Ownership Type" name="ownershipType" rules={[{ required: true, message: "Ownership Type is required" }]}>
            <Radio.Group
              options={[
                {
                  value: "direct",
                  label: "Direct Shares",
                },
                {
                  value: "indirect",
                  label: "Indirect Shares",
                },
              ]}
            />
          </Form.Item>
        </>
      )}
      {/* isOwner form item needs to be rendered for useForm to work */}
      <Form.Item name="isOwner" hidden />
      {(onboardingType === PersonFormField.DIRECTORS ||
        (isOwner && type === "individual" && percentOfOwnership && Number.parseFloat(percentOfOwnership) >= 25)) && (
        <>
          <Divider margin="medium" />
          <Heading tag="h3">Government ID Details</Heading>
          <Form.Item
            label="Upload a Copy of a Government Issued ID"
            name="sidUploads"
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }

              return e?.fileList;
            }}
            rules={[
              {
                async validator(rule, uploads) {
                  if (!uploads?.length) {
                    throw "Government Issued ID is required";
                  }
                },
              },
            ]}
          >
            <UploadDragger
              customRequest={async (options) => {
                const file = options.file as RcFile;
                await saveProgress();
                try {
                  await uploadOnboardingPersonFile(onboardingPerson.id, UploadFileType.SID, createFormData([file]));
                  notifySuccess("Document Uploaded");
                } catch (err) {
                  options.onError?.(err);
                }
              }}
              onRemove={async (file) => {
                if (file.uid) {
                  try {
                    await onRemoveDocument(onboardingPerson.id, file.uid, UploadFileType.SID);
                    notifySuccess("Document removed");
                  } catch (errors) {
                    notifyError("Uploaded Document failed");

                    return false;
                  }
                }

                return true;
              }}
              onDownload={async (file) => {
                try {
                  await downloadPOSTFile("/v1/onboarding/persons/document/download", { personId: onboardingPerson.id, uploadId: file.uid }, file.name);
                } catch {
                  notifyError("Download failed");
                }
              }}
            />
          </Form.Item>

          <Grid padding={["medium", "none"]} />
          <Form.Item label="Type of Identification" name="govIdType" rules={[{ required: true, message: "Type of Identification is required" }]}>
            <Select showSearch placeholder="Select a type" optionFilterProp="children" disabled={!sidUploads?.length}>
              {getIdTypes(countryOfIssue).map((type) => (
                <Select.Option key={type.value} value={type.value}>
                  {type.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Country of Issue" name="govIdCountry" rules={[{ required: true, message: "Country is required" }]}>
            <SelectCountry type="all" disabled={!sidUploads?.length} />
          </Form.Item>
          {countryOfIssue && (
            <Form.Item
              label={`${translateRegionByCountry(countryOfIssue)} of Issue`}
              name="govIdState"
              rules={[{ required: true, message: `${translateRegionByCountry(countryOfResidence)} is required` }]}
            >
              <SelectRegion country={countryOfIssue} disabled={!sidUploads?.length} />
            </Form.Item>
          )}
          <Form.Item label="ID Issuing Authority" name="govIdIssuingAuthority" rules={[{ required: true, message: "ID Issuing Authority is required" }]}>
            <Input name="govIdIssuingAuthority" placeholder="ID Issuing Authority" disabled={!sidUploads?.length} />
          </Form.Item>
          <Form.Item label="ID Document Number" name="govIdDocNum" rules={[{ required: true, message: "ID Document Number is required" }]}>
            <Input name="govIdDocNum" placeholder="ID Document Number" disabled={!sidUploads?.length} />
          </Form.Item>
          <Form.Item label="Issue Date" name="govIdIssue" rules={[{ required: true, message: "Issue Date is required " }, validatePastDate]}>
            <InputDate disabled={!sidUploads?.length} />
          </Form.Item>
          <Form.Item label="Expiry Date" name="govIdExpires" rules={[{ required: true, message: "Expiry Date is required " }, validateFutureDate]}>
            <InputDate disabled={!sidUploads?.length} />
          </Form.Item>
        </>
      )}
      <Form.SubmitButtons
        status={status}
        saveText="Save & Close"
        resetText="Close"
        onReset={onClose}
        addOnAfter={
          <div style={{ flex: 1 }}>
            <ButtonDelete
              confirmType="modal"
              title={`Are you you want to delete this ${personLabel.toLocaleLowerCase()}?`}
              onConfirm={async () => {
                await deleteOnboardingPerson([onboardingPerson.id]);
              }}
            >
              Delete {personLabel}
            </ButtonDelete>
          </div>
        }
      />
      <Divider transparent margin="large" />
    </Form>
  );
}

const useRadioStyle = createUseStyle(({ theme }) =>
  css`
    display: flex;
    flex-wrap: nowrap;
    .${theme.prefixCls}-radio-wrapper {
      flex: 1;
      background-color: ${theme.colorBgLayout};
      padding: 8px 4px;
      border-radius: ${theme.borderRadius}px;
      border: ${theme.lineWidth}px ${theme.lineType} ${theme.colorBorder};
      transition: all 0.3s ease;
      .icon-wrapper {
        transition: all 0.3s ease;
        padding: 16px 8px;
        background-color: ${theme.colorBgLayout};
        border-radius: 50%;
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .${theme.prefixCls}-radio {
        display: none;
      }
      &:hover {
        border: ${theme.lineWidth}px ${theme.lineType} ${theme.colorPrimaryHover};
        .icon-wrapper {
          background-color: ${getAlphaColor(theme.colorWhite, 0.7)};
        }
      }
      &.${theme.prefixCls}-radio-wrapper-checked {
        background-color: ${theme.controlItemBgActive};
        border: ${theme.lineWidth}px ${theme.lineType} ${theme.colorPrimary};
        .icon-wrapper {
          color: ${theme.colorPrimaryActive};
          background-color: ${getAlphaColor(theme.colorWhite, 0.8)};
        }
      }
    }
  `(),
);
