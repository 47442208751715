import { AUPaymentReason } from "@trolley/common-frontend";

export const PAYMENT_REASON_DETAILS: Record<AUPaymentReason, { name: string }> = {
  [AUPaymentReason.CONTENT_CREATION]: {
    name: "Content Creation",
  },
  [AUPaymentReason.OTHER_SERVICES]: {
    name: "Other Services",
  },
  [AUPaymentReason.PEER_TO_PEER]: {
    name: "Peer to Peer",
  },
  [AUPaymentReason.SKILLS_PROVISIONS]: {
    name: "Skills Provisions",
  },
  [AUPaymentReason.SUBSCRIPTION]: {
    name: "Subscription",
  },
  [AUPaymentReason.TASKING]: {
    name: "Tasking",
  },
};
