import * as types from "@trolley/common-frontend";
import {
  EndOfYear,
  EndOfYearFormType,
  EoyTaxReportDeliveryStatusEmail,
  EoyTaxReportStatus,
  SerializerMetaBlock,
  TaxDeliveryType,
  TaxEntityTypeUS,
  TaxFormType,
  EoyTaxReportTrolleyStatus,
} from "@trolley/common-frontend";
import { batch } from "react-redux";
import * as request from "services/request";
import { POST } from "services/request";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { Mapped } from "store/reducers/standardReducer";
import { queueFactory } from "utils/factories";

import { Query } from "utils/hooks";
import { computeID, isLoaded } from "./actionUtils";

export interface EoyTax extends EndOfYear.RecipientEoyTax {}

export interface EoyTaxQuery extends Query<EoyTax> {
  search?: string;
  status?: EoyTaxReportStatus[];
  formTypes?: EndOfYearFormType[];
  /** deprecated
   * use formTypes: EndOfYearFormType[]; instead
   */
  formType?: never;
  recipientId?: string;
  taxYear?: number;
  ids?: string[];
  taxFormTypes?: TaxFormType[]; // | UsUploadTaxFormType | "missing";
  taxTypes?: TaxEntityTypeUS[];
  totalEquivUntaxedAmountLt?: number;
  totalEquivUntaxedAmountGte?: number;
  requireTaxForm?: boolean;
  taxDeliveryType?: string;
  requireIrsSubmittedAt?: boolean;
  isAmended?: boolean;
  downloadedByRecipient?: boolean;
  deliveryStatusesEmail?: EoyTaxReportDeliveryStatusEmail[];
  deliveryStatusesMail?: EoyTaxReportDeliveryStatusEmail[];
  trolleyStatus?: EoyTaxReportTrolleyStatus[];
}

export interface EoyTaxUpdate {
  recipientEoyTaxIds: {
    id: string;
    hash: string;
  }[];
  status: EoyTaxReportStatus.APPROVED | EoyTaxReportStatus.HOLD | EoyTaxReportStatus.DO_NOT_SEND | EoyTaxReportStatus.VOID;
}

export const DEFAULT_QUERY = {
  page: 1,
  pageSize: 10,
};

const eoyTaxFormQueueLoader = queueFactory(
  (eoyTaxFormIds) => {
    request
      .POST<types.EndOfYear.ListRecipientEoyTax>("/v1/tax-year/eoy-form/search", {
        query: {
          ids: eoyTaxFormIds,
          pageSize: eoyTaxFormIds.length,
        },
      })
      .then(({ body }) => {
        const mappedEoyTaxes = body.recipientEoyTaxes.reduce((acc, tf) => {
          acc[tf.id] = tf;

          return acc;
        }, {});
        standardDispatch(OpCode.UPDATE, "eoyTaxForm", {
          bulk: eoyTaxFormIds.reduce((acc, id) => {
            acc[id] = mappedEoyTaxes[id] ?? undefined;

            return acc;
          }, {}),
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "eoyTaxForm", {
          ids: eoyTaxFormIds,
          errors,
        });
      });
  },
  (id) => /^RET-\w+/.test(id),
);

export function loadEoyTaxForm(id: string, force?: boolean) {
  const { recipient } = store.getState();

  if (force || !isLoaded(recipient.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "eoyTaxForm", { id });
    eoyTaxFormQueueLoader.add(id);
  }
}

export function loadEoyTaxFormsByIds(ids: string[], force: boolean = false) {
  const data = store.getState().eoyTaxForm;
  const loadIds = types.uniqueList(ids).filter((id) => force || !isLoaded(data.fetchStatus[id]));

  if (loadIds.length > 0) {
    standardDispatch(OpCode.LOADING, "eoyTaxForm", {
      ids: loadIds,
    });
    POST<{ recipientEoyTaxes: EoyTax[]; meta: SerializerMetaBlock }>("/v1/tax-year/eoy-form/search", {
      query: {
        ids: loadIds,
        pageSize: loadIds.length,
      },
    })
      .then(({ body }) => {
        const mappedEoyTaxes = body.recipientEoyTaxes.reduce((acc, tf) => {
          acc[tf.id] = tf;

          return acc;
        }, {});
        standardDispatch(OpCode.UPDATE, "eoyTaxForm", {
          bulk: loadIds.reduce((acc, id) => {
            acc[id] = mappedEoyTaxes[id] ?? undefined;

            return acc;
          }, {}),
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.LOADING, "eoyTaxForm", {
          loading: false,
          ids: loadIds,
        });
      });
  }
}

export function resetEoyTaxForms() {
  standardDispatch(OpCode.RESET, "eoyTaxForms");
}

export function loadEoyTaxForms(query: EoyTaxQuery, force?: boolean): string {
  const data = store.getState().eoyTaxForms;
  const id = computeID(query);

  if (force || !isLoaded(data.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "eoyTaxForms", { id });
    POST<{ recipientEoyTaxes: EoyTax[]; meta: SerializerMetaBlock }>("/v1/tax-year/eoy-form/search", { query })
      .then(({ body }) => {
        batch(() => {
          const recipientEoyTax: Mapped<EoyTax> = {};
          const ids: string[] = body.recipientEoyTaxes.map((ret) => {
            recipientEoyTax[ret.id] = ret;

            return ret.id;
          });
          standardDispatch(OpCode.DATA, "eoyTaxForm", {
            bulk: recipientEoyTax,
          });

          standardDispatch(OpCode.DATA, "eoyTaxForms", {
            id,
            data: {
              records: ids,
              meta: body.meta,
            },
          });
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "eoyTaxForms", {
          errors,
          id,
        });
      });
  }

  return id;
}

export async function updateEoyTaxForms(taxYear: number, query: EoyTaxUpdate) {
  try {
    standardDispatch(OpCode.LOADING, "eoyTaxForms");

    const { body } = await POST<{ recipientEoyTaxIds: string[]; errors: string[] }>("/v1/tax-year/eoy-form/update", {
      query: {
        ...query,
        taxYear,
      },
    });
    standardDispatch(OpCode.LOADING, "eoyTaxForms", {
      loading: false,
    });

    if (body.recipientEoyTaxIds.length) {
      loadEoyTaxFormsByIds(body.recipientEoyTaxIds, true);
    }

    if (body.errors.length) {
      throw body.errors.map((message) => ({ code: "api_error", message }));
    }

    return body.recipientEoyTaxIds;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "eoyTaxForms", { errors });
    throw errors;
  }
}

type MarkSendQuery = {
  recipientEoyTaxIds?: string[];
  formTypes?: EndOfYearFormType[];
};

export async function sendEoyTaxForms(taxYear: number, query: MarkSendQuery) {
  try {
    standardDispatch(OpCode.LOADING, "eoyTaxForms");

    const { body } = await POST<{ recipientEoyTaxIds: string[]; errors: string[] }>("/v1/tax-year/eoy-form/mark-sent", {
      query: {
        ...query,
        taxYear,
      },
    });
    standardDispatch(OpCode.LOADING, "eoyTaxForms", {
      loading: false,
    });

    if (body.recipientEoyTaxIds.length) {
      loadEoyTaxFormsByIds(body.recipientEoyTaxIds, true);
    }

    if (body.errors.length) {
      throw body.errors.map((message) => ({ code: "api_error", message }));
    }

    return body.recipientEoyTaxIds;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "eoyTaxForms", {
      errors,
    });
    throw errors;
  }
}

type ReSendQuery = {
  recipientEoyTaxIds?: string[];
  taxDeliveryType?: TaxDeliveryType;
  formTypes?: EndOfYearFormType[];
};

export async function resendEoyTaxForms(taxYear: number, query: ReSendQuery) {
  try {
    standardDispatch(OpCode.LOADING, "eoyTaxForms");

    const { body } = await POST<{ recipientEoyTaxIds: string[]; errors: string[] }>("/v1/tax-year/eoy-form/resend", {
      query: {
        ...query,
        taxYear,
      },
    });
    standardDispatch(OpCode.LOADING, "eoyTaxForms", {
      loading: false,
    });

    if (body.recipientEoyTaxIds.length) {
      loadEoyTaxFormsByIds(body.recipientEoyTaxIds, true);
    }

    if (body.errors.length > 0 || body.recipientEoyTaxIds.length === 0) {
      throw body.errors.map((message) => ({ code: "api_error", message }));
    }

    return body.recipientEoyTaxIds;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "eoyTaxForms", {
      errors,
    });
    throw errors;
  }
}

export async function bulkUpdate(action: "approve-all", query: EoyTaxQuery) {
  const id = computeID(query);
  try {
    standardDispatch(OpCode.LOADING, "eoyTaxForms", { id });
    await POST(`/v1/tax-year/eoy-form/${action}`, {
      query,
    });
    standardDispatch(OpCode.RESET, "eoyTaxForms");
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "eoyTaxForms", { id, errors });
    throw errors;
  }
}
