import {
  AUPaymentReason,
  Access,
  CurrencyCode,
  PaymentCategory,
  PaymentTaxesPayoutMethods,
  PayoutMethodType,
  TaxStatus,
  isCurrencyAmount,
} from "@trolley/common-frontend";
import BigNumber from "bignumber.js";
import {
  Button,
  ButtonDelete,
  Checkbox,
  DateDisplay,
  DatePicker,
  Divider,
  Flag,
  Form,
  Input,
  InputNumber,
  LabelTooltip,
  Modal,
  Select,
  Space,
  Text,
  getLabelTooltip,
} from "components";
import dayjs from "dayjs";
import { PayoutMethodDisplay } from "features/payoutMethod";
import { RecipientProfile } from "features/recipient";
import { TaxProfileDisplay } from "features/taxProfile";
import React, { useEffect } from "react";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { DAC7Tax, createPaymentDac7Tax, updatePaymentDac7Tax, deletePaymentDac7Taxes, resetDAC7Tax } from "store/actions/paymentDac7Taxes";
import { useShallowSelector } from "store/hooks";
import { MerchantSettings, useMerchantSettings } from "store/hooks/merchantSettings";
import { useRecipientTaxProfiles } from "store/hooks/recipientTaxProfiles";
import { useAccess } from "store/hooks/user";
import { BaseStatus } from "store/reducers/standardReducer";
import { CATEGORY_DETAILS, NON_TAXABLE_CATEGORIES_DAC7 } from "utils/constants";
import { PAYMENT_REASON_DETAILS } from "utils/constants/paymentReasons";
import { getDefaultReportingCurrency, handleFormErrors, omitEmpty } from "utils/helpers";

interface Props {
  dac7Tax: Partial<DAC7Tax> | undefined;
  onClose(): void;
}

type FormFields = {
  payoutMethod: PayoutMethodType;
  category: PaymentCategory;
  taxReportable: boolean;
  enteredCurrency?: string;
  enteredAmount?: string;
  feeAmount?: string;
  taxAmount?: string;
  relevantActivities: number;
  processedAt: string;
  externalId?: string;
  taxProfileId: string | null;
  feesCurrency?: string;
  taxesCurrency?: string;
  feesTaxesCurrency?: string;
};

const DEFAULT_DAC7_FIELDS = {
  relevantActivities: 1,
  enteredAmount: "0.00",
  enteredCurrency: CurrencyCode.EUR,
  feeAmount: "0.00",
  taxAmount: "0.00",
};

function getCategoryToShow(
  dac7Info: Partial<DAC7Tax> | undefined,
  isAUMarketplace: boolean,
  merchantSettings: MerchantSettings | undefined,
): PaymentCategory | AUPaymentReason {
  if (isAUMarketplace) {
    return dac7Info?.paymentReason ?? AUPaymentReason.CONTENT_CREATION;
  }

  return dac7Info?.category ?? merchantSettings?.payment?.defaultCategory ?? PaymentCategory.SERVICES;
}

export default function PaymentTaxRecordDAC7Edit(props: Props) {
  const [form] = Form.useForm<FormFields>();
  const { dac7Tax, onClose } = props;
  const recipientId = dac7Tax?.recipientId;
  const visible = !!dac7Tax;
  const accessPaymentWrite = useAccess(Access.PAYMENTS_WRITE);
  const { data: merchantSettings, features } = useMerchantSettings();
  const taxProfilesNeeded = !!features.euTax && !!merchantSettings?.euTax?.enabled && !!dac7Tax?.id; // we auto find tax profile
  const isAUMarketplace = merchantSettings?.supportedTaxRegions?.AU;
  const { data: recipientTaxProfiles } = useRecipientTaxProfiles(
    recipientId,
    {
      pageSize: 1000,
      status: [TaxStatus.REVIEWED, TaxStatus.SUBMITTED, TaxStatus.EXPIRED, TaxStatus.VOIDED],
    },
    taxProfilesNeeded,
  );
  const paymentTaxStatus = useShallowSelector((state) => state.paymentTaxes.fetchStatus.LOADING);

  const paymentCategories = Object.entries(merchantSettings?.payment?.categories || {}) as [PaymentCategory, boolean][];
  const enabledCategories = paymentCategories.filter(([c, enabled]) => enabled && CATEGORY_DETAILS[c]);

  const defaultReportingCurrency = getDefaultReportingCurrency(merchantSettings?.supportedTaxRegions);

  useEffect(() => {
    if (dac7Tax) {
      const initialValues = omitEmpty({
        ...dac7Tax,
        category: getCategoryToShow(dac7Tax, isAUMarketplace ?? false, merchantSettings),
        taxReportable: dac7Tax.id ? !!dac7Tax.taxReportable : true,
        feeAmount: dac7Tax.platformFee?.value ?? DEFAULT_DAC7_FIELDS.feeAmount,
        taxAmount: dac7Tax.tax?.value ?? DEFAULT_DAC7_FIELDS.taxAmount,
        relevantActivities: dac7Tax?.relevantActivities ?? DEFAULT_DAC7_FIELDS.relevantActivities,
        enteredCurrency: dac7Tax.enteredAmount?.currency ?? defaultReportingCurrency,
        enteredAmount: dac7Tax.enteredAmount?.value ?? DEFAULT_DAC7_FIELDS.enteredAmount,
        externalId: dac7Tax?.externalId ?? null,
        taxProfileId: dac7Tax?.taxProfileId ?? null,
        feesTaxesAmount: dac7Tax?.feesTaxes?.value ?? "0.00",
        feesCurrency: dac7Tax.platformFee?.currency ?? defaultReportingCurrency,
        taxesCurrency: dac7Tax.tax?.currency ?? defaultReportingCurrency,
        feesTaxesCurrency: dac7Tax.feesTaxes?.currency ?? defaultReportingCurrency,
      } as any);
      // use setFieldsValue instead of Form's initialValues. because form instance is kept when switching between accounts
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [dac7Tax]);

  async function onSubmit({ relevantActivities, ...values }: any) {
    if (recipientId) {
      try {
        if (dac7Tax?.id) {
          const post = {
            ...values,
            relevantActivities: +relevantActivities,
            taxCurrency: form.getFieldValue("taxesCurrency"),
            feeCurrency: form.getFieldValue("feesCurrency"),
            ...(isAUMarketplace && { feesTaxesCurrency: form.getFieldValue("feesTaxesCurrency") }),
          };
          await updatePaymentDac7Tax(dac7Tax.id, post);
          notifySuccess("Payment Updated");
        } else {
          const post = {
            ...values,
            relevantActivities: +relevantActivities,
            taxCurrency: form.getFieldValue("taxesCurrency"),
            feeCurrency: form.getFieldValue("feesCurrency"),
            ...(isAUMarketplace && { feesTaxesCurrency: form.getFieldValue("feesTaxesCurrency") }),
            recipientId: dac7Tax.recipientId,
          };
          await createPaymentDac7Tax(post);
          notifySuccess("Offline Payment Updated");
        }
        onClose();
      } catch (errors) {
        handleFormErrors(errors, form);
      }
    }
  }

  return (
    <Modal
      width={600}
      title={dac7Tax?.paymentId ? "Payment Tax Record" : "Offline Payment"}
      visible={visible}
      onCancel={onClose}
      footer={
        <Space direction="row-reverse">
          <Button
            type="primary"
            onClick={form.submit}
            loading={paymentTaxStatus === BaseStatus.LOADING}
            disabled={!accessPaymentWrite}
            tooltipProps={
              accessPaymentWrite
                ? undefined
                : {
                    title: "You do not have access permission. Please contact your administrators to change your role to gain permission.",
                    placement: "topRight",
                  }
            }
          >
            {`${dac7Tax?.id ? "Save" : "Add"} ${dac7Tax?.paymentId ? "Payment Tax Record" : "Offline Payment"}`}
          </Button>
          <Button onClick={onClose}>Cancel</Button>
          {accessPaymentWrite && dac7Tax?.id && !dac7Tax?.paymentId && (
            <div style={{ flex: "1", textAlign: "left" }}>
              <ButtonDelete
                ghost
                title="Delete Offline Payment?"
                onConfirm={async () => {
                  if (dac7Tax?.id) {
                    try {
                      await deletePaymentDac7Taxes([dac7Tax.id]);
                      onClose();
                      resetDAC7Tax();
                    } catch (errors) {
                      notifyError("Delete Failed", { errors });
                    }
                  }
                }}
              >
                Delete
              </ButtonDelete>
            </div>
          )}
        </Space>
      }
    >
      {dac7Tax && (
        <Form
          layout="horizontal"
          labelAlign="right"
          labelCol={{ flex: "220px" }}
          form={form}
          onFinish={onSubmit}
          validateTrigger="onChange"
          disabled={!accessPaymentWrite}
        >
          {dac7Tax.recipientId && (
            <>
              <RecipientProfile recipientId={dac7Tax.recipientId} showStatus showAddress showEmail />
              <Divider margin="medium" />
            </>
          )}

          <Form.Item label="Payout Method" name="payoutMethod">
            <Select>
              {Object.values(PaymentTaxesPayoutMethods).map((pm) => (
                <Select.Option key={pm} value={pm}>
                  <PayoutMethodDisplay value={pm} showLabel />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item {...getLabelTooltip("category")} name="category">
            {isAUMarketplace ? (
              <Select<AUPaymentReason> style={{ width: "100%" }} popupMatchSelectWidth={false}>
                {Object.entries(PAYMENT_REASON_DETAILS).map(([pReasonOption, pReasonValue]) => (
                  <Select.Option key={pReasonOption}>{pReasonValue.name}</Select.Option>
                ))}
              </Select>
            ) : (
              <Select<PaymentCategory>
                style={{ width: "100%" }}
                popupMatchSelectWidth={false}
                onChange={(value) => {
                  const updates: Partial<FormFields> = {};
                  if (value) {
                    if (NON_TAXABLE_CATEGORIES_DAC7.includes(value)) {
                      // force value to false as it does not apply to category
                      updates.taxReportable = false;
                    }
                  }

                  if (Object.keys(updates).length) {
                    form.setFieldsValue(updates);
                  }
                }}
              >
                {enabledCategories.map(([categoryOption]) => (
                  <Select.Option key={categoryOption}>{CATEGORY_DETAILS[categoryOption]?.name || categoryOption}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Control dependencies={["category"]}>
            {({ getFieldValue }) => {
              const category = getFieldValue("category");

              return (
                <>
                  {category && (
                    <Form.Item {...getLabelTooltip("taxReportable")} name="taxReportable" valuePropName="checked">
                      <Checkbox disabled={!accessPaymentWrite || NON_TAXABLE_CATEGORIES_DAC7.includes(category)}>
                        {NON_TAXABLE_CATEGORIES_DAC7.includes(category) && <Text size="small">{CATEGORY_DETAILS[category]?.name} are non-taxable</Text>}
                      </Checkbox>
                    </Form.Item>
                  )}
                </>
              );
            }}
          </Form.Control>

          <Form.Item label="Currency" name="enteredCurrency" rules={[{ required: true, message: "Select a currency" }]}>
            <Select
              popupMatchSelectWidth={false}
              onChange={(value: CurrencyCode) => {
                form.setFieldsValue({
                  enteredCurrency: value,
                  feesCurrency: value,
                  taxesCurrency: value,
                  feesTaxesCurrency: value,
                });
              }}
            >
              {Object.keys(CurrencyCode).map((c: CurrencyCode) => (
                <Select.Option key={c} value={c}>
                  <Flag code={c} showLabel />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Control dependencies={["enteredCurrency"]}>
            {({ getFieldValue }) => {
              const enteredCurrency = getFieldValue("enteredCurrency");

              return (
                <Form.Item
                  label="Entered Amount"
                  name="enteredAmount"
                  rules={[
                    { required: true, message: "Entered Amount is required" },
                    { pattern: isCurrencyAmount, message: "Must be a valid amount." },
                  ]}
                >
                  <InputNumber name="enteredAmount" inputMode="decimal" addonAfter={enteredCurrency} />
                </Form.Item>
              );
            }}
          </Form.Control>

          <Form.Control dependencies={["enteredCurrency"]}>
            {({ getFieldValue }) => {
              const feesCurrency = getFieldValue("feesCurrency");

              return (
                <Form.Item
                  label="Fees"
                  name="feeAmount"
                  rules={[
                    { required: true, message: "Fees Amount is required" },
                    { pattern: isCurrencyAmount, message: "Must be a valid amount." },
                  ]}
                >
                  <InputNumber name="feeAmount" inputMode="decimal" addonAfter={feesCurrency} />
                </Form.Item>
              );
            }}
          </Form.Control>

          <Form.Control dependencies={["enteredCurrency"]}>
            {({ getFieldValue }) => {
              const taxesCurrency = getFieldValue("taxesCurrency");

              return (
                <Form.Item
                  label="Taxes"
                  name="taxAmount"
                  rules={[
                    { required: true, message: "Taxes Amount is required" },
                    { pattern: isCurrencyAmount, message: "Must be a valid amount." },
                  ]}
                >
                  <InputNumber name="taxAmount" inputMode="decimal" addonAfter={taxesCurrency} />
                </Form.Item>
              );
            }}
          </Form.Control>

          {isAUMarketplace && (
            <Form.Control dependencies={["enteredCurrency"]}>
              {({ getFieldValue }) => {
                const feesTaxesCurrency = getFieldValue("feesTaxesCurrency");

                return (
                  <Form.Item
                    label="Fees Taxes"
                    name="feesTaxesAmount"
                    rules={[
                      { required: true, message: "Fees Taxes Amount is required" },
                      { pattern: isCurrencyAmount, message: "Must be a valid amount." },
                    ]}
                  >
                    <InputNumber name="taxAmount" inputMode="decimal" addonAfter={feesTaxesCurrency} />
                  </Form.Item>
                );
              }}
            </Form.Control>
          )}

          <Form.Item
            name="relevantActivities"
            label={<LabelTooltip type="dac7FieldsRelevantActivities" />}
            rules={[
              { required: true, message: "Relevant Activities is required" },
              {
                async validator(rule, value) {
                  const numberRA = new BigNumber(value);
                  if (!numberRA.isGreaterThan(0) || !numberRA.isInteger()) {
                    throw "Must be a whole positive number";
                  }
                },
              },
            ]}
          >
            <InputNumber autoFocus inputMode="numeric" name="relevantActivities" />
          </Form.Item>

          <Form.Item
            label="Processed On"
            name="processedAt"
            normalize={(newValue: string | undefined) => {
              return newValue ? (dayjs.utc(newValue).isSame(dac7Tax.processedAt, "date") ? dac7Tax.processedAt : newValue) : undefined;
            }}
            rules={[{ required: true, message: "Select a date" }]}
          >
            <DatePicker allowClear={false} type="past" />
          </Form.Item>

          <Form.Item label="External ID" name="externalId">
            <Input autoComplete="off" />
          </Form.Item>

          {taxProfilesNeeded && (
            <Form.Item label="Tax Profile" name="taxProfileId" normalize={(v) => v ?? null}>
              <Select allowClear style={{ width: "100%" }}>
                {recipientTaxProfiles.records
                  .filter((tf) => !!tf.signedAt)
                  .map((tf) => (
                    <Select.Option key={tf.id} value={tf.id}>
                      <div>
                        <TaxProfileDisplay taxProfileId={tf.id} showStatus />
                        <br />
                        <Text type="secondary" size="small">
                          Signed on <DateDisplay value={tf.signedAt} icon={false} time={false} />
                          {tf.status === TaxStatus.VOIDED && (
                            <>
                              , Voided on <DateDisplay value={tf.signedAt} icon={false} time={false} />
                            </>
                          )}
                        </Text>
                      </div>
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          )}
        </Form>
      )}
    </Modal>
  );
}
