import { Access, CountryCode, formatCountry, isSupportedOnboardingCountry, OnboardingType } from "@trolley/common-frontend";
import { FormInstance, Result } from "antd";
import { FormProps } from "antd/lib/form";
import { NamePath } from "antd/lib/form/interface";
import {
  Box,
  Button,
  ButtonLink,
  Checkbox,
  Container,
  Divider,
  ExternalLink,
  Form,
  Grid,
  Heading,
  Icon,
  Modal,
  SelectCountry,
  Text,
  Typography,
} from "components";
import { ONBOARDING_TOUR_LINK } from "features/onboarding/ActivateAlert";
import { BANK_ONBOARDING_PATH } from "pages/BankTransferOnboarding";
import React, { useLayoutEffect, useState } from "react";
import Helmet from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import { asyncLoadMerchantSettings } from "store/actions/merchantSettings";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { completeOnboarding, Onboarding, OnboardingUpdate, updateOnboarding } from "store/actions/onboarding";
import {
  createOnboardingPerson,
  deleteOnboardingPerson,
  OnboardingPerson,
  OnboardingPersonUpdate,
  updateOnboardingPerson,
} from "store/actions/onboardingPersons";
import { isMerchantApproved, useMerchantSettings } from "store/hooks/merchantSettings";
import { getBankingOnboardingEligibility, useOnboarding } from "store/hooks/onboarding";
import { useAccess, useUser } from "store/hooks/user";
import { BaseStatus } from "store/reducers/standardReducer";
import { asyncDelay, handleFormErrors, omit, omitMaskedValues, pickDiff } from "utils/helpers";
import { useWindowSize } from "utils/hooks";
import AddtionalDoc from "./AddtionalDoc";
import CompanyDetails from "./CompanyDetails";
import CompanyRegistrationCertificate from "./CompanyRegistrationCertificate";
import OnboardingSteps from "./OnboardingSteps";
import PayoutInformation from "./PayoutInformation";
import SigningOfficer, { FIELD_DIRECTORS } from "./SigningOfficer";

export const BUSINESS_ONBOARDING_PATH = "/activate";

export enum FormNames {
  COMPANY_DETAILS = "companyDetails",
  COMPANY_CERTIFICATE = "businessCertificate",
  SIGNING_OFFICER = "signingOfficers",
  PAYOUT_INFORMATION = "payoutInformation",
  ADDITIONAL_DOC = "additionalDoc", // not a form
  COMPLETE = "complete",
}

export function onFinishOnboarding(record: Onboarding | undefined, form: FormInstance): FormProps["onFinish"] {
  return (values) => {
    submitOnboarding(record, form, values)
      .then(() => {
        form.resetFields();
      })
      .catch((err) => {
        //
      });
  };
}

export function onFinishFailedOnboarding(record: Onboarding | undefined, form: FormInstance): FormProps["onFinishFailed"] {
  return ({ errorFields, values }) => {
    submitOnboarding(record, form, values, errorFields)
      .then(async () => {
        await form.validateFields();
      })
      .catch((err) => {
        //
      });
  };
}

async function submitOnboarding(
  record: Onboarding | undefined,
  form: FormInstance,
  values: Partial<Onboarding>,
  errorFields: {
    name: NamePath;
    errors: string[];
  }[] = [],
) {
  if (record) {
    if (FIELD_DIRECTORS in values) {
      const formDirectors = (values[FIELD_DIRECTORS] as ({ id: string } & Partial<OnboardingPerson>)[]) || [];
      const toRemove = record.persons.filter((person) => person.isDirector && !formDirectors.find((director) => director.id === person.id)).map((p) => p.id);

      const { toAdd, toUpdate } = formDirectors.reduce(
        (acc, director) => {
          if (director.id) {
            if (director.id.includes("add")) {
              acc.toAdd.push(director);
            } else {
              const originalPerson = record.persons.find((person) => person.id === director.id);
              const diffUpdate = originalPerson && pickDiff(director, originalPerson);
              if (diffUpdate) {
                acc.toUpdate.push({ ...diffUpdate, id: originalPerson.id });
              }
            }
          }

          return acc;
        },
        {
          toAdd: [],
          toUpdate: [],
        } as { toAdd: Partial<OnboardingPerson>[]; toUpdate: ({ id: string } & Partial<OnboardingPerson>)[] },
      );
      if (toAdd.length) {
        await Promise.all(toAdd.map(({ id, ...p }) => createOnboardingPerson({ ...p, isDirector: true, type: "individual" })));
      }
      if (toUpdate.length) {
        await Promise.all(toUpdate.map(({ id, ...p }) => updateOnboardingPerson(id, { ...p, isDirector: true, type: "individual" } as OnboardingPersonUpdate)));
      }
      if (toRemove.length) {
        await deleteOnboardingPerson(toRemove);
      }
    } else {
      const uploadData = pickDiff(
        omit(
          omitMaskedValues(values),
          errorFields.map((err) => String(err.name[0])),
        ),
        record,
      );

      if (uploadData) {
        try {
          await updateOnboarding(uploadData as OnboardingUpdate);
          notifySuccess("Changes saved");
        } catch (error) {
          handleFormErrors(error, form, "Saving failed");
          throw error;
        }
      }
      await asyncDelay(100); // allow dispatch to resolve
    }
  }
}

export default function BusinessProfile() {
  const history = useHistory();
  const { isMobile } = useWindowSize();
  const { data: user } = useUser();
  const { data: merchantSettings } = useMerchantSettings();
  const { data: onboarding, status: onboardingStatus } = useOnboarding();
  const [hasError, setError] = useState("");
  const canEdit = useAccess(Access.ONBOARDING_WRITE);
  const accessSigningOfficer = useAccess(Access.SIGNING_OFFICER);
  const canComplete = accessSigningOfficer && user?.status === "active";
  const isComplete = !!onboarding?.completedAt;
  const businessCountry = onboarding?.businessCountry || undefined;
  const businessType = onboarding?.businessType || undefined;
  const countryLabel = formatCountry(businessCountry);

  useLayoutEffect(() => {
    if (merchantSettings?.sandbox || merchantSettings?.onboardingType === OnboardingType.LIGHT) {
      history.push("/");
    }
  }, [merchantSettings]);

  if (!canEdit) {
    return (
      <Result
        icon={<Icon type="do-not-enter" theme="solid" color="red" size="4x" />}
        title="Access Restricted"
        subTitle="Only owners and admins have access to this section."
        extra={
          <ButtonLink type="primary" size="large" ghost path="/">
            Return to Dashboard
          </ButtonLink>
        }
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Activate your Trolley Account</title>
      </Helmet>

      <Modal
        title="Acknowledgements"
        visible={onboarding && (!onboarding.eSignDisclosureAgreement || !onboarding.patriotActNoticeAgreement)}
        onCancel={() => history.push("/")}
        onOk={async () => {
          await updateOnboarding({
            eSignDisclosureAgreement: new Date().toISOString(),
            patriotActNoticeAgreement: new Date().toISOString(),
          });
        }}
        okText="I Agree"
        cancelText="Exit Onboarding"
      >
        <Heading
          tag="h3"
          description="This Electronic Disclosure and Consent Agreement (“Disclosure”) applies to all communications, disclosures, agreements, statements, notices, and documents related to the products and services offered by Trolley US Inc. (“we,” “us,” or “our”) that are provided to you electronically."
        >
          E-SIGN Disclosure
        </Heading>
        <Text>
          By agreeing to this Disclosure, you consent to receive all such communications electronically rather than in paper form. You are also confirming that
          you meet the hardware and software requirements and that you can access, receive, and retain electronic communications. For more details, read our
          full <ExternalLink href="https://go.trolley.com/e-sign-consent-disclosure">E-SIGN Disclosure here.</ExternalLink>
        </Text>

        <Heading
          tag="h3"
          description="To help the government fight the funding of terrorism and money laundering activities, Federal law requires financial institutions to obtain, verify, and record information that identifies each individual or entity that opens an account."
        >
          PATRIOT Act Notice
        </Heading>
        <Text>
          What this means for you: When you open an account with Trolley, we will ask for your name, address, date of birth, corporate documents, and other
          information that will allow us to identify you and the business you wish to onboard with Trolley. We may also ask to see your driver’s license or
          other identifying documents.
        </Text>
      </Modal>

      {onboarding && (
        <Container maxWidth="lg" padding="large">
          {isSupportedOnboardingCountry(businessCountry) ? (
            <>
              <Heading>Activate your Trolley Account</Heading>
              <p style={{ maxWidth: "60ch" }}>Activate your account today to enable global transfers and other features.</p>
              <Divider transparent margin="medium" />
              <Grid padding="large" justify="space-between" wrap={isMobile}>
                <Grid.Item flex={isMobile ? "1 1 100%" : "1 0 200px"} style={{ maxWidth: isMobile ? "100%" : "200px" }}>
                  <OnboardingSteps />
                </Grid.Item>
                <Grid.Item flex={isMobile ? "100%" : "1 1 400px"} style={{ maxWidth: isMobile ? "100%" : "600px" }}>
                  <Form.Provider
                    onFormChange={() => {
                      setError("");
                    }}
                    onFormFinish={async (formName: FormNames, { forms }) => {
                      if (formName === FormNames.COMPLETE) {
                        let error = "";
                        const orderedForms = Object.entries(forms)
                          .filter(([name]) => name !== formName)
                          .sort(([formA], [formB]) => {
                            const formNames: string[] = Object.values(FormNames);

                            return formNames.indexOf(formA.split("-")[0]) - formNames.indexOf(formB.split("-")[0]);
                          });

                        orderedForms
                          .reduce(async (prev, [formName, form]: [string, FormInstance]) => {
                            return prev.then(async (success) => {
                              if (success) {
                                try {
                                  if (!form.isFieldsTouched()) {
                                    // there may still be errors on the form validation
                                    await form.validateFields();

                                    return true;
                                  }

                                  error = "The form contains unsaved changes. Please save all changes first.";

                                  return false;
                                } catch (err) {
                                  error = "The form is incomplete. Please fill in all required information first.";
                                  const name = err?.errorFields?.[0]?.name;

                                  if (name) {
                                    form.scrollToField(name, {
                                      behavior(actions) {
                                        actions.forEach(({ el, top }) => {
                                          if (top !== el.scrollTop) {
                                            el.scrollTop = top - (isMobile ? 40 : 120); // consider the page heading height + label height
                                          }
                                        });
                                      },
                                    });
                                  }

                                  return false;
                                }
                              }

                              return false;
                            });
                          }, Promise.resolve(true))
                          .then(async (allValid) => {
                            if (allValid) {
                              setError("");
                              if (isComplete) {
                                notifySuccess("Business Profile Saved");
                              } else if (canComplete) {
                                try {
                                  const newOnboarding = await completeOnboarding();
                                  const { merchantSettings: merchant } = await asyncLoadMerchantSettings();
                                  const isApproved = isMerchantApproved(merchant);
                                  const isEligibleForBanking = getBankingOnboardingEligibility(newOnboarding, merchant);

                                  const modal = Modal.success({
                                    icon: !isApproved ? <Icon type="clock-three" color="purple" /> : undefined,
                                    title: isApproved ? "Congratulations!" : "Your account details are currently being reviewed.",
                                    content: (
                                      <>
                                        {isApproved ? (
                                          <>
                                            <Typography.Paragraph>
                                              Your account has been approved to send PayPal
                                              {merchantSettings?.country === CountryCode.US && " and Check"} payouts.
                                            </Typography.Paragraph>
                                            {isEligibleForBanking ? (
                                              <Typography.Paragraph>
                                                If you’re interested in sending bank transfer payouts, please continue by filling out our{" "}
                                                <Link
                                                  to={BANK_ONBOARDING_PATH}
                                                  onClick={() => {
                                                    modal.destroy();
                                                  }}
                                                >
                                                  Bank Transfer Onboarding Form
                                                </Link>
                                                .
                                              </Typography.Paragraph>
                                            ) : (
                                              <>
                                                <Typography.Paragraph>
                                                  We recommend starting by adding recipients on the{" "}
                                                  <Link
                                                    to="/recipients"
                                                    onClick={() => {
                                                      modal.destroy();
                                                    }}
                                                  >
                                                    recipient page.
                                                  </Link>
                                                </Typography.Paragraph>
                                                <Typography.Paragraph>
                                                  Click here for the{" "}
                                                  <ExternalLink href={ONBOARDING_TOUR_LINK}>
                                                    Onboarding Guide.
                                                    <Icon type="external-link" right />
                                                  </ExternalLink>
                                                </Typography.Paragraph>
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <>We will reach out via email regarding account activation within 5 business days.</>
                                        )}
                                      </>
                                    ),
                                    okText: isApproved
                                      ? isEligibleForBanking
                                        ? "Go to Bank Transfer Onboarding Form"
                                        : "Go to Recipient Page"
                                      : "Return to Dashboard",
                                    onOk: () => {
                                      history.push(isApproved ? (isEligibleForBanking ? BANK_ONBOARDING_PATH : "/recipients") : "/");
                                      modal.destroy();
                                    },
                                  });
                                } catch (errors) {
                                  notifyError("Something went wrong activating your account..", { errors });
                                }
                              }
                            } else {
                              setError(error || "The onboarding form is incomplete. See errors above.");
                            }
                          })
                          .catch((err) => {
                            //
                          });
                      }
                    }}
                  >
                    <CompanyDetails />

                    {!!businessCountry && !!businessType && (
                      <>
                        <CompanyRegistrationCertificate />
                        <SigningOfficer />
                        <PayoutInformation />
                        <AddtionalDoc />

                        {!isComplete && (
                          <Box>
                            <Form name={FormNames.COMPLETE}>
                              {canComplete && (
                                <Form.Item
                                  name="terms"
                                  valuePropName="checked"
                                  rules={[
                                    {
                                      async validator(rule, checked) {
                                        if (!checked) {
                                          throw "You must confirm and agree to the terms above";
                                        }
                                      },
                                    },
                                  ]}
                                >
                                  <Checkbox>
                                    I confirm that I have the legal authority to create an account on behalf of the Business and that the information I have
                                    given is valid and truthful.
                                    <br />I also confirm I agree to the{" "}
                                    <ExternalLink href="https://www.trolley.com/legal-agreements">Trolley Terms of Service and Privacy Policy</ExternalLink>.
                                  </Checkbox>
                                </Form.Item>
                              )}

                              <Divider transparent margin="small" />
                              <Text padded type="error">
                                {hasError}
                              </Text>
                              <Button
                                size="large"
                                type="primary"
                                htmlType="submit"
                                loading={onboardingStatus === BaseStatus.LOADING}
                                disabled={!canComplete}
                                block
                                tooltipProps={
                                  !canComplete
                                    ? { title: accessSigningOfficer ? "Only Signing Officers can submit this form" : "Please confirm your email first" }
                                    : undefined
                                }
                              >
                                Submit Onboarding
                              </Button>
                            </Form>
                          </Box>
                        )}
                      </>
                    )}
                  </Form.Provider>
                </Grid.Item>
              </Grid>
            </>
          ) : (
            <>
              <Heading>Your Registered Business Country is not supported</Heading>

              <p style={{ maxWidth: "60ch" }}>
                {countryLabel} is not supported on the Trolley platform today. If you have a business registered in another country, please choose it below to
                see if it's supported on Trolley. If not, we've tracked your interest and will notify you when we launch in your business' country.
              </p>
              <Form
                onFinish={async (values: { businessCountry: CountryCode }) => {
                  await updateOnboarding({ businessCountry: values.businessCountry });
                }}
              >
                <Form.Item name="businessCountry" rules={[{ required: true, message: "Please select supported Country for you business Country" }]}>
                  <SelectCountry type="onboarding" size="large" />
                </Form.Item>
                <Form.SubmitButtons status={onboardingStatus} saveText="Update Business Country" />
              </Form>
            </>
          )}
        </Container>
      )}
    </>
  );
}
