import { Container, Heading, RecordCount, Table, TableColumnsType, Typography, Status } from "components";
import React, { useState } from "react";
import Helmet from "react-helmet";
import { WatchlistVerification } from "store/actions/verifications";
import WatchlistVerificationPreview from "./WatchlistVerificationPreview";

interface Props {
  verifications: WatchlistVerification[];
}

enum ColumnKeys {
  ID = "id",
  NAME = "name",
  STATUS = "status",
  SUBMITTED_AT = "submittedAt",
}

export default function WatchlistVerification(props: Props) {
  const { verifications } = props;
  const [previewWatchlistVerification, setPreviewWatchlistVerification] = useState<WatchlistVerification | undefined>();
  const columns: TableColumnsType<WatchlistVerification> = [
    {
      key: ColumnKeys.ID,
      title: "Watchlist ID",
      render: ({ id }: WatchlistVerification) => <Typography.Text>{id || "-"}</Typography.Text>,
    },
    {
      key: ColumnKeys.NAME,
      title: "Name",
      render: ({ verifiedData }: WatchlistVerification) => <Typography.Text>{verifiedData.name ? verifiedData.name : "-"}</Typography.Text>,
    },
    {
      key: ColumnKeys.STATUS,
      title: "Status",
      render: ({ status }: WatchlistVerification) => (status ? <Status type={status} size="small" right /> : "-"),
    },
    {
      key: ColumnKeys.SUBMITTED_AT,
      title: "Submitted At",
      render: ({ createdAt }: WatchlistVerification) => (createdAt ? createdAt : "-"),
    },
  ];

  return (
    <Container>
      <Helmet>
        <title>Watchlist Verification</title>
      </Helmet>
      <Heading tag="h2">
        <RecordCount prefix="Showing" value={verifications.length} one="1 watchlist verification" other="# watchlist verifications" />
      </Heading>

      <Table<WatchlistVerification>
        columns={columns}
        dataSource={verifications}
        emptyProps={{
          description: "No watchlist verifications submitted",
        }}
        onRow={(record) => ({
          onClick: () => {
            setPreviewWatchlistVerification(record);
          },
        })}
      />

      <WatchlistVerificationPreview
        WatchlistVerification={previewWatchlistVerification}
        onClose={() => {
          setPreviewWatchlistVerification(undefined);
        }}
      />
    </Container>
  );
}
