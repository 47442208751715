import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import * as request from "services/request";
import * as types from "@trolley/common-frontend";
import { loadOnboarding } from "./onboarding";

export type OnboardingPerson = types.Person;

export type OnboardingPersonUpdate = Partial<
  Pick<
    OnboardingPerson,
    | "type"
    | "isContact"
    | "isDirector"
    | "isOwner"
    | "firstName"
    | "lastName"
    | "name"
    | "title"
    | "dateOfBirth"
    | "personalTaxId"
    | "phone"
    | "email"
    | "address"
    | "city"
    | "region"
    | "zip"
    | "country"
    | "nationalities"
    | "percentOfOwnership"
    | "ownershipType"
    | "govIdUpload"
    | "govIdType"
    | "govIdCountry"
    | "govIdState"
    | "govIdIssuingAuthority"
    | "govIdDocNum"
    | "govIdIssue"
    | "govIdExpires"
    | "isPEP"
  >
>;

export async function createOnboardingPerson(data: OnboardingPersonUpdate) {
  try {
    standardDispatch(OpCode.LOADING, "onboarding");
    const { body } = await request.POST<types.PersonResult>("/v1/onboarding/persons/create", { query: data });
    const onboarding = store.getState().onboarding.entities.data;
    if (onboarding) {
      const persons = [...(onboarding.persons || []), body.person];
      standardDispatch(OpCode.UPDATE, "onboarding", {
        update: { persons },
      });
    }

    return body.person;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "onboarding", {
      errors,
    });
    throw errors;
  }
}

export async function updateOnboardingPerson(personId: string, query: OnboardingPersonUpdate) {
  const { onboarding } = store.getState();
  try {
    standardDispatch(OpCode.LOADING, "onboarding");
    const { body } = await request.POST<types.PersonResult>("/v1/onboarding/persons/update", {
      query: { ...query, personId },
    });

    standardDispatch(OpCode.UPDATE, "onboarding", {
      update: {
        persons: [...(onboarding.entities.data?.persons?.map((person) => (person.id === body.person.id ? body.person : person)) || [])],
      },
    });

    return body.person;
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "onboarding", {
      errors,
    });
    throw errors;
  }
}

export async function deleteOnboardingPerson(ids: string[]) {
  standardDispatch(OpCode.LOADING, "onboarding");
  const { onboarding } = store.getState();

  try {
    const settled = await Promise.allSettled(ids.map((id) => request.POST("/v1/onboarding/persons/delete", { query: { personId: id } })));

    const deletedIds = settled.map((s, index) => (s.status === "fulfilled" ? ids[index] : undefined)).filter((id) => id);
    standardDispatch(OpCode.UPDATE, "onboarding", {
      update: {
        persons: [...(onboarding.entities.data?.persons?.filter((person) => !deletedIds.includes(person.id)) || [])],
      },
    });
  } catch (errors) {
    standardDispatch(OpCode.ERROR, "onboarding", errors);

    throw errors;
  }
}

export async function uploadOnboardingPersonFile(personId: string, type: string, file: FormData) {
  file.set("type", type);
  file.set("personId", personId);
  const { body } = await request.POST<{ uploadId: string }>("/v1/onboarding/persons/document/upload", {
    isUpload: true,
    body: file,
  });
  loadOnboarding(true);

  return body.uploadId;
}

export async function removeOnboardingPersonFile(personId: string, uploadId: string) {
  await request.POST("/v1/onboarding/persons/document/delete", {
    query: {
      personId,
      uploadId,
    },
  });
  loadOnboarding(true);
}
