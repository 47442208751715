import { countryHasPostalCode } from "@trolley/common-frontend";
import { OnboardingPerson } from "store/actions/onboardingPersons";

export function getPersonRequiredFields(person: OnboardingPerson, type: "directors" | "owners"): string[] {
  const requiredFields: string[] = ["type"];
  const businessRequiredFields = ["name"];
  const individualRequiredFields = [
    "title",
    "firstName",
    "lastName",
    "address",
    "city",
    "region",
    "isPEP",
    "phone",
    "email",
    "dateOfBirth",
    "nationalities",
    "personalTaxId",
  ];

  if (countryHasPostalCode(person.country)) {
    individualRequiredFields.push("zip");
  }

  if (type === "directors") {
    requiredFields.push(
      ...individualRequiredFields, // because directors are always individuals
      "isContact",
      "govIdType",
      "govIdCountry",
      "govIdState",
      "govIdIssuingAuthority",
      "govIdDocNum",
      "govIdIssue",
      "govIdExpires",
    );
  }

  if (type === "owners") {
    if (person.type === "individual") {
      requiredFields.push(...individualRequiredFields);
    }
    if (person.type === "business") {
      requiredFields.push(...businessRequiredFields);
    }
  }

  if (person.isOwner) {
    requiredFields.push("percentOfOwnership", "ownershipType");
  }

  return requiredFields;
}

export function isPersonComplete(person: OnboardingPerson | undefined, type: "directors" | "owners"): boolean {
  if (!person) {
    return false;
  }

  const requiredFields = getPersonRequiredFields(person, type);

  // check every field has values
  if (
    !requiredFields.every((key) => {
      const val = person?.[key];
      if (Array.isArray(val)) {
        return val.filter((v) => v).length > 0;
      }

      return val !== null && val !== undefined && val !== "";
    })
  ) {
    return false;
  }

  if (type === "directors") {
    const files = Object.values(person.files || {});
    if (!files.some((file) => file.type === "por") || !files.some((file) => file.type === "sid")) {
      return false;
    }
  }

  return true;
}
