import { Box, CopyToClipboard, Divider, Status, Flyout, Form, FormItem } from "components";
import { RecipientPreview, RecipientProfile } from "features/recipient";
import React, { useState } from "react";
import { WatchlistVerification } from "store/actions/verifications";
import { Link } from "react-router-dom";

interface Props {
  WatchlistVerification?: WatchlistVerification;
  onClose(): void;
}

export default function WatchlistVerificationPreview(props: Props) {
  const { WatchlistVerification, onClose } = props;
  const [recipientId, setRecipientId] = useState<string | undefined>();

  const getCountryField = (fields: { name: string; value: string; source: string }[]) => {
    const countryField = fields.find((field) => field.name === "Country");

    return countryField ? countryField.value : "-";
  };

  return (
    <Flyout
      visible={!!WatchlistVerification}
      onClose={onClose}
      title={
        WatchlistVerification && (
          <>
            Watchlist Verification <Status type={WatchlistVerification.status} size="small" right />
          </>
        )
      }
      subtitle={
        WatchlistVerification && (
          <>
            <Divider margin="medium" />
            <RecipientProfile
              recipientId={WatchlistVerification.recipientId}
              showLink={() => {
                setRecipientId(WatchlistVerification.recipientId);
              }}
              size="large"
              showEmail
              showAddress
            />
          </>
        )
      }
    >
      {WatchlistVerification && (
        <Form layout="horizontal" compact>
          <Box padding="large" header="Verification Details">
            <FormItem label="Verification ID">
              <CopyToClipboard value={WatchlistVerification.id} />
            </FormItem>
            <FormItem label="Name">{WatchlistVerification.verifiedData?.name || "-"}</FormItem>
            <FormItem label="Country">
              {WatchlistVerification.verifiedData?.hits?.[0]?.doc?.fields ? getCountryField(WatchlistVerification.verifiedData.hits[0].doc.fields) : "-"}
            </FormItem>
          </Box>
          {WatchlistVerification.verifiedData?.hits?.map(
            (hit) =>
              hit.doc?.sourceNotes && (
                <Box key={hit.doc?.name} padding="large" header={`Matches for ${hit.doc?.name || "-"}`}>
                  <FormItem label="Match Name">{hit.doc?.name || "-"}</FormItem>
                  {Object.entries(hit.doc.sourceNotes).map(
                    ([source, note]) =>
                      note && (
                        <Box key={source} padding="small" header={`${note.name || "-"} Match`}>
                          <Link to={`${note.url}`}>{`${note.url}`}</Link>
                        </Box>
                      ),
                  )}
                </Box>
              ),
          )}
        </Form>
      )}
      <RecipientPreview
        recipientId={recipientId}
        onClose={() => {
          setRecipientId(undefined);
        }}
      />
    </Flyout>
  );
}
