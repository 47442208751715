import { TFAType, TransactionInfo } from "@trolley/common-frontend";
import React from "react";

import { Button, Divider, Modal } from "components";
import { solveLoginChallenge } from "services/webauthn";
import css from "style/classname";
import TFAForm from "./TFAForm";

interface Config {
  submitText?: string;
  title?: string;
  errorFieldName?: string;
}

export interface TFAParams {
  tfaType: string;
  challenge?: any;
  transactionInfo?: TransactionInfo;
  url?: string;
  deviceId?: string;
}

function redirectPopover({ transactionInfo }: TFAParams) {
  if (!transactionInfo) return false;
  const { isPayScaSMSTfa } = transactionInfo;

  return !!isPayScaSMSTfa;
}

export default async function promptTFA<T>(tfaParams: TFAParams, callback: (tfaOtp: string) => Promise<T>, config?: Config): Promise<T> {
  return new Promise((resolve, reject) => {
    if (tfaParams.tfaType === TFAType.WEBAUTHN && tfaParams?.challenge) {
      solveLoginChallenge(JSON.parse(tfaParams.challenge))
        .then((credentials) => {
          return callback(JSON.stringify(credentials));
        })
        .then(resolve)
        .catch(() => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject([{ field: config?.errorFieldName, code: TFAErrors.WEBAUTHN_SOLVE_ERROR, message: "Invalid Two-Factor authentication device" }]);
        });
    } else if (redirectPopover(tfaParams)) {
      reject(tfaParams);
    } else {
      const modal = Modal.info({
        width: 300,
        icon: null,
        maskClosable: true,
        wrapClassName: styledModal,
        onCancel: () => {
          reject();
        },
        title: config?.title || "Two-Factor Authentication Code",
        content: (
          <TFAForm
            tfaParams={tfaParams}
            submitText={config?.submitText}
            onValidate={async (code) => {
              const value = await callback(code);
              resolve(value);
              modal.destroy();
            }}
            onTimeout={() => {
              modal.update({
                content: (
                  <>
                    The code has expired.
                    <Divider transparent margin="medium" />
                    <Button
                      block
                      size="large"
                      type="primary"
                      onClick={() => {
                        reject(); // reject the initial request that prompted this TFA modal
                        modal.destroy(); // then close this modal.
                      }}
                    >
                      Close
                    </Button>
                  </>
                ),
              });
            }}
          />
        ),
      });
    }
  });
}

export enum TFAErrors {
  MISSING_WEBAUTHN_CREDENTIALS = "missing_webauthn_credentials",
  WEBAUTHN_SOLVE_ERROR = "webauthn_solve_error",
}

const styledModal = css`
  [class*="-modal-confirm-btns"] {
    display: none;
  }
`();
