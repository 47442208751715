import { Tabs } from "antd";
import React from "react";
import { Redirect, Route, Switch, useHistory, useParams } from "react-router-dom";
import { DAC7TaxMerchantYear } from "store/actions/dac7TaxMerchantYear";
import { Badge, Box, Button, Container, DateDisplay, Dropdown, Heading, Icon, Menu, Text } from "components";
import MerchantDAC7TaxInfo from "pages/TaxCenter/EndOfYear/DAC7/MerchantDAC7TaxInfo";
import ReviewEarnings from "pages/TaxCenter/EndOfYear/DAC7/ReviewEarnings";
import css, { createUseStyle } from "style/classname";
import { TAX_PATHS, DAC7_TAX_YEARS, DAC7_INTRO_YEAR } from "../..";
import DAC7Statement from "./DAC7Statement";
import dayjs from "dayjs";
import { DAC7EoyTaxQuery } from "store/actions/dac7EoyTaxForms";
import { EoyTaxReportStatus, SupportedTaxRegions } from "@trolley/common-frontend";
import { useDac7EoySummary } from "store/hooks/dac7EoySummary";
import { BaseStatus } from "store/reducers/standardReducer";
import { useDAC7TaxMerchantYear } from "store/hooks/dac7TaxMerchantYear";
import { useMerchantSettings } from "store/hooks/merchantSettings";

export enum Pages {
  EARNINGS = "review-earnings",
  DELIVERYDAC7 = "dac7-statements",
  PROFILE = "business-tax-profile",
}

export function getDAC7LastYear() {
  return Math.max(dayjs().subtract(1, "year").year(), DAC7_INTRO_YEAR);
}

// /**
//  *
//  * @param taxMerchantYear
//  * @param eFiling for downloading the FIRE E-File format
//  */
export function isProfileComplete(dac7TaxMerchantYear?: DAC7TaxMerchantYear): boolean {
  if (!dac7TaxMerchantYear) {
    return false;
  }

  return dac7TaxMerchantYear.complete;
}

// const QUERIES: EoyTaxQuery[] = [
//   // inReview:
//   {
//     pageSize: 0,
//     status: [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD],
//     requireTaxForm: true,
//   },
//   // inReview1099:
//   {
//     pageSize: 0,
//     formTypes: [EndOfYearFormType.F1099],
//     status: [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD],
//     requireTaxForm: true,
//   },
//   // inProgress1099:
//   {
//     pageSize: 0,
//     formTypes: [EndOfYearFormType.F1099],
//     status: [EoyTaxReportStatus.APPROVED, EoyTaxReportStatus.SENT],
//     requireIrsSubmittedAt: false,
//   },
//   // completed1099:
//   {
//     pageSize: 0,
//     formTypes: [EndOfYearFormType.F1099],
//     status: [EoyTaxReportStatus.SENT, EoyTaxReportStatus.VOID],
//     requireIrsSubmittedAt: true,
//   },

//   // inReview1099-K:
//   {
//     pageSize: 0,
//     formTypes: [EndOfYearFormType.F1099_K],
//     status: [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD],
//     // requireTaxForm: true,
//   },
//   // inProgress1099-K:
//   {
//     pageSize: 0,
//     formTypes: [EndOfYearFormType.F1099_K],
//     status: [EoyTaxReportStatus.APPROVED, EoyTaxReportStatus.SENT],
//     requireIrsSubmittedAt: false,
//   },
//   // completed1099-K:
//   {
//     pageSize: 0,
//     formTypes: [EndOfYearFormType.F1099_K],
//     status: [EoyTaxReportStatus.SENT, EoyTaxReportStatus.VOID],
//     requireIrsSubmittedAt: true,
//   },

//   // inReview1099NEC:
//   {
//     pageSize: 0,
//     formTypes: [EndOfYearFormType.F1099_NEC],
//     status: [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD],
//     requireTaxForm: true,
//   },
//   // inProgress1099NEC:
//   {
//     pageSize: 0,
//     formTypes: [EndOfYearFormType.F1099_NEC],
//     status: [EoyTaxReportStatus.APPROVED, EoyTaxReportStatus.SENT],
//     requireIrsSubmittedAt: false,
//   },
//   // completed1099NEC:
//   {
//     pageSize: 0,
//     formTypes: [EndOfYearFormType.F1099_NEC],
//     status: [EoyTaxReportStatus.SENT, EoyTaxReportStatus.VOID],
//     requireIrsSubmittedAt: true,
//   },

//   // inReview1042:
//   {
//     pageSize: 0,
//     formTypes: [EndOfYearFormType.F1042],
//     status: [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD],
//     requireTaxForm: true,
//   },
//   // inProgress1042:
//   {
//     pageSize: 0,
//     formTypes: [EndOfYearFormType.F1042],
//     status: [EoyTaxReportStatus.APPROVED, EoyTaxReportStatus.SENT],
//     requireIrsSubmittedAt: false,
//   },
//   // completed1042:
//   {
//     pageSize: 0,
//     formTypes: [EndOfYearFormType.F1042],
//     status: [EoyTaxReportStatus.SENT, EoyTaxReportStatus.VOID],
//     requireIrsSubmittedAt: true,
//   },
// ];

const QUERIES: DAC7EoyTaxQuery[] = [
  // inReview:
  {
    pageSize: 0,
    reportToRegion: [SupportedTaxRegions.EU, SupportedTaxRegions.AU, SupportedTaxRegions.UK, SupportedTaxRegions.NZ],
    status: [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD],
  },
  // inReviewEU:
  {
    pageSize: 0,
    reportToRegion: [SupportedTaxRegions.EU],
    status: [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD],
  },
  // inProgressEU:
  {
    pageSize: 0,
    reportToRegion: [SupportedTaxRegions.EU],
    status: [EoyTaxReportStatus.APPROVED, EoyTaxReportStatus.SENT],
    submitted: false,
  },
  // completedEU:
  {
    pageSize: 0,
    reportToRegion: [SupportedTaxRegions.EU],
    // status: [EoyTaxReportStatus.SENT, EoyTaxReportStatus.VOID],
    submitted: true,
  },
  // inReviewAU:
  {
    pageSize: 0,
    reportToRegion: [SupportedTaxRegions.AU],
    status: [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD],
  },
  // inProgressAU:
  {
    pageSize: 0,
    reportToRegion: [SupportedTaxRegions.AU],
    status: [EoyTaxReportStatus.APPROVED],
    // The submitted attribute is not used because we are not generating XML files yet and marking them as sent for AU
  },
  // completedAU:
  {
    pageSize: 0,
    reportToRegion: [SupportedTaxRegions.AU],
    status: [EoyTaxReportStatus.SENT, EoyTaxReportStatus.VOID],
    // The submitted attribute is not used because we are not generating XML files yet and marking them as sent for AU
  },
  // inReviewUK:
  {
    pageSize: 0,
    reportToRegion: [SupportedTaxRegions.UK],
    status: [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD],
  },
  // inProgressUK:
  {
    pageSize: 0,
    reportToRegion: [SupportedTaxRegions.UK],
    status: [EoyTaxReportStatus.APPROVED, EoyTaxReportStatus.SENT],
    submitted: false,
  },
  // completedUK:
  {
    pageSize: 0,
    reportToRegion: [SupportedTaxRegions.UK],
    // status: [EoyTaxReportStatus.SENT, EoyTaxReportStatus.VOID],
    submitted: true,
  },
  // inReviewNZ:
  {
    pageSize: 0,
    reportToRegion: [SupportedTaxRegions.NZ],
    status: [EoyTaxReportStatus.NEEDS_REVIEW, EoyTaxReportStatus.HOLD],
  },
  // inProgressNZ:
  {
    pageSize: 0,
    reportToRegion: [SupportedTaxRegions.NZ],
    status: [EoyTaxReportStatus.APPROVED, EoyTaxReportStatus.SENT],
    submitted: false,
  },
  // completedNZ:
  {
    pageSize: 0,
    reportToRegion: [SupportedTaxRegions.NZ],
    // status: [EoyTaxReportStatus.SENT, EoyTaxReportStatus.VOID],
    submitted: true,
  },
];

export function getDueDate(taxYear: number, reportingMarket: SupportedTaxRegions) {
  const currentYear = taxYear + 1;

  // TODO: Due dates should be verified each year
  const DatesFn = {
    [SupportedTaxRegions.EU]: (year: number) => `${currentYear}-01-31`,
    [SupportedTaxRegions.UK]: (year: number) => `${currentYear}-01-31`,
    [SupportedTaxRegions.AU]: (year: number) => `${currentYear}-01-31`,
    [SupportedTaxRegions.NZ]: (year: number) => `${currentYear}-02-07`,
    // CA is not available yet, it's declared just to avoid lint errors
    [SupportedTaxRegions.CA]: (year: number) => `${currentYear}-01-31`,
  };

  return DatesFn[reportingMarket](currentYear);
}

export default function EndOfYearPage() {
  const styledTabs = useStyledTabs();
  const {
    push,
    replace,
    location: { pathname, search },
  } = useHistory();
  const params = useParams<{ taxYear?: string }>();
  const taxYear = Number(params.taxYear);
  const { data: eoySummary, status: summaryStatus } = useDac7EoySummary(QUERIES.map((q) => ({ ...q, taxYear })));
  const [
    inReview,
    inReviewEU,
    inProgressEU,
    completedEU,
    inReviewAU,
    inProgressAU,
    completedAU,
    inReviewUK,
    inProgressUK,
    completedUK,
    inReviewNZ,
    inProgressNZ,
    completedNZ,
  ] = eoySummary;
  const incompleteEU = inReviewEU + inProgressEU;
  const incompleteAU = inReviewAU + inProgressAU;
  const incompleteUK = inReviewUK + inProgressUK;
  const incompleteNZ = inReviewNZ + inProgressNZ;
  const { data: dac7TaxMerchantYear } = useDAC7TaxMerchantYear(taxYear);
  const isBusinessProfileComplete = isProfileComplete(dac7TaxMerchantYear);
  const baseUrl = `${TAX_PATHS.EOY_REPORTING_DAC7}/${taxYear}`;
  const basePath = `${TAX_PATHS.EOY_REPORTING_DAC7}/:taxYear(\\d{4})`;
  const { data: merchantSettings } = useMerchantSettings();

  if (Number.isNaN(taxYear)) {
    replace({ pathname: [TAX_PATHS.EOY_REPORTING_DAC7, getDAC7LastYear()].join("/") });
  }

  return (
    <Container>
      <Heading>
        End of Year Review &amp; Filing for&nbsp;
        <Dropdown
          overlay={
            <Menu
              onClick={(e) => {
                const newTaxYear = e.key;
                push({
                  pathname: pathname.replace(String(taxYear), newTaxYear),
                  search,
                });
              }}
            >
              {DAC7_TAX_YEARS.map((year) => {
                return (
                  <Menu.Item key={year} disabled={taxYear === year}>
                    Tax Year {year}
                  </Menu.Item>
                );
              })}
            </Menu>
          }
        >
          <Button type="text" size="large" style={{ fontSize: "inherit", lineHeight: 1 }}>
            Tax Year {taxYear}
            <Icon type="angle-down" right />
          </Button>
        </Dropdown>
      </Heading>

      <Tabs
        type="card"
        activeKey={pathname}
        onChange={(id: Pages) => {
          push(id, {
            scrollToTop: false,
          });
        }}
        className={styledTabs}
      >
        <Tabs.TabPane
          key={`${baseUrl}/${Pages.EARNINGS}`}
          tab={
            <div>
              Review Earnings
              {summaryStatus === BaseStatus.LOADED && (inReview > 0 ? <Badge count={inReview} offset={[4, -6]} /> : <Icon.Status type="success" right />)}
            </div>
          }
        />
        {taxYear >= DAC7_INTRO_YEAR && merchantSettings?.supportedTaxRegions?.EU && incompleteEU + completedEU > 0 && (
          <Tabs.TabPane
            key={`${baseUrl}/${Pages.DELIVERYDAC7}/${SupportedTaxRegions.EU.toLowerCase()}`}
            tab={
              <div>
                EU Reporting
                {summaryStatus === BaseStatus.LOADED &&
                  (incompleteEU > 0 ? <Badge count={incompleteEU} offset={[4, -6]} /> : <Icon.Status type="success" right />)}
                <Text size="small" type="secondary">
                  Due <DateDisplay value={getDueDate(taxYear, SupportedTaxRegions.EU)} time={false} icon={false} />
                </Text>
              </div>
            }
          />
        )}
        {taxYear >= DAC7_INTRO_YEAR && merchantSettings?.supportedTaxRegions?.AU && incompleteAU + completedAU > 0 && (
          <Tabs.TabPane
            key={`${baseUrl}/${Pages.DELIVERYDAC7}/${SupportedTaxRegions.AU.toLowerCase()}`}
            tab={
              <div>
                AU Reporting
                {summaryStatus === BaseStatus.LOADED &&
                  (incompleteAU > 0 ? <Badge count={incompleteAU} offset={[4, -6]} /> : <Icon.Status type="success" right />)}
                <Text size="small" type="secondary">
                  Due <DateDisplay value={getDueDate(taxYear, SupportedTaxRegions.AU)} time={false} icon={false} />
                </Text>
              </div>
            }
          />
        )}
        {taxYear >= DAC7_INTRO_YEAR && merchantSettings?.supportedTaxRegions?.UK && incompleteUK + completedUK > 0 && (
          <Tabs.TabPane
            key={`${baseUrl}/${Pages.DELIVERYDAC7}/${SupportedTaxRegions.UK.toLowerCase()}`}
            tab={
              <div>
                UK Reporting
                {summaryStatus === BaseStatus.LOADED &&
                  (incompleteUK > 0 ? <Badge count={incompleteUK} offset={[4, -6]} /> : <Icon.Status type="success" right />)}
                <Text size="small" type="secondary">
                  Due <DateDisplay value={getDueDate(taxYear, SupportedTaxRegions.UK)} time={false} icon={false} />
                </Text>
              </div>
            }
          />
        )}
        {taxYear >= DAC7_INTRO_YEAR && merchantSettings?.supportedTaxRegions?.NZ && incompleteNZ + completedNZ > 0 && (
          <Tabs.TabPane
            key={`${baseUrl}/${Pages.DELIVERYDAC7}/${SupportedTaxRegions.NZ.toLowerCase()}`}
            tab={
              <div>
                NZ Reporting
                {summaryStatus === BaseStatus.LOADED &&
                  (incompleteNZ > 0 ? <Badge count={incompleteNZ} offset={[4, -6]} /> : <Icon.Status type="success" right />)}
                <Text size="small" type="secondary">
                  Due <DateDisplay value={getDueDate(taxYear, SupportedTaxRegions.NZ)} time={false} icon={false} />
                </Text>
              </div>
            }
          />
        )}
        <Tabs.TabPane
          key={`${baseUrl}/${Pages.PROFILE}`}
          tab={
            <div>
              Business Tax Profile
              {isBusinessProfileComplete && <Icon.Status type="success" right />}
            </div>
          }
        />
      </Tabs>

      <Box className={styledTabContent}>
        <Switch>
          {taxYear >= DAC7_INTRO_YEAR && merchantSettings?.supportedTaxRegions?.EU && incompleteEU + completedEU > 0 && (
            <Route
              path={`${basePath}/${Pages.DELIVERYDAC7}/${SupportedTaxRegions.EU.toLowerCase()}`}
              render={() => <DAC7Statement reportingMarket={SupportedTaxRegions.EU} />}
            />
          )}
          {taxYear >= DAC7_INTRO_YEAR && merchantSettings?.supportedTaxRegions?.AU && incompleteAU + completedAU > 0 && (
            <Route
              path={`${basePath}/${Pages.DELIVERYDAC7}/${SupportedTaxRegions.AU.toLowerCase()}`}
              render={() => <DAC7Statement reportingMarket={SupportedTaxRegions.AU} />}
            />
          )}
          {taxYear >= DAC7_INTRO_YEAR && merchantSettings?.supportedTaxRegions?.UK && incompleteUK + completedUK > 0 && (
            <Route
              path={`${basePath}/${Pages.DELIVERYDAC7}/${SupportedTaxRegions.UK.toLowerCase()}`}
              render={() => <DAC7Statement reportingMarket={SupportedTaxRegions.UK} />}
            />
          )}
          {taxYear >= DAC7_INTRO_YEAR && merchantSettings?.supportedTaxRegions?.NZ && incompleteNZ + completedNZ > 0 && (
            <Route
              path={`${basePath}/${Pages.DELIVERYDAC7}/${SupportedTaxRegions.NZ.toLowerCase()}`}
              render={() => <DAC7Statement reportingMarket={SupportedTaxRegions.NZ} />}
            />
          )}

          <Route path={`${basePath}/${Pages.PROFILE}`} component={MerchantDAC7TaxInfo} />
          <Route path={`${basePath}/${Pages.EARNINGS}`} component={ReviewEarnings} />
          <Redirect to={`${basePath}/${Pages.EARNINGS}`} />
        </Switch>
      </Box>
    </Container>
  );
}

const useStyledTabs = createUseStyle(({ theme }) =>
  css`
    &.${theme.prefixCls}-tabs {
      .${theme.prefixCls}-tabs-nav {
        margin-bottom: 0;
        &::before {
          display: none;
        }
        .${theme.prefixCls}-tabs-nav-wrap {
          .${theme.prefixCls}-tabs-tab {
            align-items: flex-start;
            border-radius: 8px 8px 0 0;
            border-width: 0px;
            box-shadow: ${theme.boxShadowTertiary};
          }
        }
      }
    }
  `(),
);

const styledTabContent = css`
  &.box {
    border-top-left-radius: 0;
    margin-top: 0;
  }
`();
