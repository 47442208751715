import { Access, CountryCode, CurrencyCode } from "@trolley/common-frontend";

import { Collapse } from "antd";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CurrencyDisplay,
  DateDisplay,
  Divider,
  ExternalLink,
  Flag,
  Form,
  Heading,
  Icon,
  Link,
  Modal,
  Space,
  Status,
  Text,
} from "components";
import { downloadPOSTFile } from "components/FileDownload";
import { BUSINESS_ONBOARDING_PATH } from "pages/BusinessProfile";
import { getTaxIdLabel } from "pages/BusinessProfile/CompanyDetails";
import { BUSINESS_CATEGORY } from "pages/BusinessProfile/PayoutInformation";
import { getBusinessTypes, getIdTypes } from "pages/BusinessProfile/variables";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { notifyError } from "store/actions/notifications";
import { completeBankOnboarding } from "store/actions/onboarding";
import { useMerchantSettings } from "store/hooks/merchantSettings";
import { useOnboarding } from "store/hooks/onboarding";
import { useAccess, useUser } from "store/hooks/user";
import { BaseStatus } from "store/reducers/standardReducer";
import styled, { createUseStyle } from "style/classname";
import { ANIMATION } from "style/variables";
import { stringifyAddress } from "utils/helpers";
import { OnboardingSection } from ".";
import { getShareRegisters } from "./ShareRegister";
import { isPersonComplete } from "./variables";

export default function ReviewCompletion() {
  const history = useHistory();
  const { data: user } = useUser();
  const { data: onboarding, status: onboardingStatus } = useOnboarding();
  const accessSigningOfficer = useAccess(Access.SIGNING_OFFICER);
  const isUserActive = user?.status === "active";
  const canComplete = accessSigningOfficer && isUserActive;
  const [viewMore, setViewMore] = useState(false);
  const directors = onboarding?.persons.filter((p) => p.isDirector) || [];
  const owners = onboarding?.persons.filter((p) => p.isOwner) || [];
  const { data: merchantSettings } = useMerchantSettings();
  const styledErrors = useStyledErrors();

  async function onCompleteBankTransfer() {
    try {
      await completeBankOnboarding();
      const modal = Modal.info({
        icon: <Icon type="clock-three" color="purple" />,
        title: "Bank Activation Form submitted",
        content: (
          <>
            Your account may be auto-approved for Third-Party Wallet Transactions (like PayPal
            {merchantSettings?.country === CountryCode.US ? ", and Checks" : ""}). You can get started by{" "}
            <a
              role="link"
              onClick={() => {
                modal.destroy();
                history.push("/settings/payout-methods");
              }}
            >
              setting up Payout Methods.
            </a>
          </>
        ),
        onOk: () => {
          history.push("/");
          modal.destroy();
        },
        okText: "Return to Dashboard",
      });
    } catch (errors) {
      notifyError("Failed to submit form", { errors });
    }
  }

  function renderFile(f: { id: string; uri: string; type?: string | undefined }) {
    return (
      <div key={f.id}>
        <Icon
          type="cloud-arrow-down"
          left
          onClick={async () => {
            try {
              await downloadPOSTFile("/v1/onboarding/documents/download", { uploadId: f.id }, f.uri);
            } catch {
              notifyError("Download failed");
            }
          }}
        />
        {(f.uri || "").substring((f.uri || "").lastIndexOf("/") + 1)}
      </div>
    );
  }

  function renderPersonsFile(personId: string, f: { id: string; uri: string; type?: string | undefined }) {
    return (
      <div key={f.id}>
        <Icon
          type="cloud-arrow-down"
          left
          onClick={async () => {
            try {
              await downloadPOSTFile("/v1/onboarding/persons/document/download", { personId, uploadId: f.id }, f.uri);
            } catch {
              notifyError("Download failed");
            }
          }}
        />
        {(f.uri || "").substring((f.uri || "").lastIndexOf("/") + 1)}
      </div>
    );
  }

  return (
    <OnboardingSection title="Review your Bank Transfer Activation">
      {onboarding && (
        <Form compact layout="horizontal" labelCol={{ flex: "50%" }} onFinish={onCompleteBankTransfer}>
          <Heading tag="h3">Company Details Summary</Heading>
          <Box padding="small" flat>
            <Alert type="info" header="Do you want to update this information? ">
              Go to <Link to={BUSINESS_ONBOARDING_PATH}>Business Profile Onboarding</Link>
            </Alert>

            <Form.Item label="Business Registration Country">
              <Flag code={onboarding.businessCountry} />
            </Form.Item>
            <Form.Item label="Business Structure">
              {getBusinessTypes(onboarding.businessCountry).find((type) => type.value === onboarding.businessType)?.label || onboarding.businessType}
            </Form.Item>

            <Form.Item label="Business Name">{onboarding.businessLegalName}</Form.Item>
            <Form.Item label="'Doing Business As' Name">{onboarding.businessAsName}</Form.Item>

            {!viewMore ? (
              <a
                role="button"
                onClick={() => {
                  setViewMore(true);
                }}
              >
                <Icon type="angle-down" left />
                View more Business Profile Details
              </a>
            ) : (
              <div style={{ animation: `${ANIMATION.fadeInDown} 0.375s ease` }}>
                <Form.Item label={getTaxIdLabel(onboarding.businessCountry)}>{onboarding.businessTaxId}</Form.Item>
                <Form.Item label="Business Phone">{onboarding?.businessPhone}</Form.Item>
                <Form.Item label="Business Address">
                  {stringifyAddress(
                    {
                      street1: onboarding.businessAddress,
                      city: onboarding.businessCity,
                      region: onboarding.businessRegion,
                      zip: onboarding.businessZip,
                    },
                    true,
                  )}
                </Form.Item>
                <Form.Item label="Business Certificate">
                  {Object.values(onboarding.files)
                    .filter((f) => f.type === "crc")
                    .map(renderFile)}
                </Form.Item>
                <Form.Item label="Primary Currency">
                  <Flag code={onboarding.primaryCurrency} />
                </Form.Item>
                <Form.Item label="Business Category">
                  {(onboarding.businessCategory && BUSINESS_CATEGORY[onboarding.businessCategory]) || onboarding.businessCategory}
                </Form.Item>
                <Form.Item labelCol={{ span: 24 }} label="How many individual payouts do expect to send each month?">
                  {onboarding.businessPpm} payments
                </Form.Item>
                <Form.Item labelCol={{ span: 24 }} label="What is total volume of monthly payments you expect to send?">
                  <CurrencyDisplay value={onboarding.businessTotalMonthly} currency={CurrencyCode.USD} />
                </Form.Item>
                <Form.Item labelCol={{ span: 24 }} label="What percentage (%) of the above payment volume will be sent internationally?">
                  {onboarding.businessIntlPercentage || "0"}%
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 24 }}
                  label="Which countries will you send payments to over the next 12 months?"
                  tooltip={
                    <>
                      This is to set a baseline list of countries for your account. You will still be able to make payments to other countries not selected
                      here. Note: unnecessarily selecting many/all countries may result in additional account review during the onboarding process. We therefore
                      kindly suggest that you answer this question as accurately as possible.
                    </>
                  }
                >
                  <Space direction="column" align="start">
                    {onboarding.expectedPayoutCountries?.map((code) => (
                      <Flag key={code} code={code} />
                    ))}
                  </Space>
                </Form.Item>
                <Form.Item labelCol={{ span: 24 }} label="What is the purpose of your payments?">
                  {onboarding.businessPurpose}
                </Form.Item>
              </div>
            )}
          </Box>

          <Heading tag="h3">Business Structure</Heading>
          <Box flat padding="small">
            {!onboarding.noOwnership ? (
              <>
                <Text weight="bold">One or several owners own more than 25%,</Text>
                <Text>directly or indirectly, or has controlling interest.</Text>
              </>
            ) : (
              <>
                <Text weight="bold">No owner owns more that 25%,</Text>
                <Text>directly or indirectly, nor has controlling interest.</Text>
              </>
            )}

            <Divider transparent margin="small" />
            <Form.Item labelCol={{ span: 24 }} label="Shareholder Register">
              {Object.values(onboarding.files)
                .filter((f) => f.type === "shareRegister")
                .map(renderFile)}
            </Form.Item>
          </Box>

          <Heading tag="h3">Signing Officers</Heading>
          <Box padding="none" flat>
            <Collapse accordion style={{ backgroundColor: "transparent" }} bordered={false}>
              {directors.map((director, index) => (
                <Collapse.Panel
                  header={
                    <strong>
                      Signing Officer #{index + 1}: {[director.firstName, director.lastName].join(" ")}
                    </strong>
                  }
                  extra={director.isContact && <Status type="success">PRIMARY CONTACT</Status>}
                  key={director.id}
                >
                  <Form.Item label="Full Name">{[director.firstName, director.lastName].join(" ")}</Form.Item>
                  <Form.Item label="Position/Title">{director.title}</Form.Item>
                  <Form.Item label="Phone Number">{director.phone}</Form.Item>
                  <Form.Item label="Email Address">{director.email}</Form.Item>
                  <Form.Item label="Residential Address">
                    {stringifyAddress({
                      address: director.address,
                      city: director.city,
                      region: director.region,
                      zip: director.zip,
                      country: director.country,
                    })}
                  </Form.Item>
                  <Form.Item label="Email Address">{director.email}</Form.Item>
                  <Form.Item label="Proof of Residence">
                    {Object.values(director.files)
                      .filter((f) => f.type === "por")
                      .map((file) => {
                        return renderPersonsFile(director.id, file);
                      })}
                  </Form.Item>
                  <Form.Item label="Date of Birth">
                    <DateDisplay value={director.dateOfBirth} time={false} />
                  </Form.Item>
                  <Form.Item label="Nationalities">
                    <Space direction="column" align="start">
                      {director.nationalities
                        ?.filter((v) => v)
                        .map((code) => (
                          <Flag key={code} code={code} />
                        ))}
                    </Space>
                  </Form.Item>
                  {[director.country, ...(director.nationalities ?? [])].includes(CountryCode.US) && (
                    <Form.Item label="SSN or ITIN" tooltip="Social Security Number or Individual Tax Identification Number">
                      {director.personalTaxId}
                    </Form.Item>
                  )}
                  {![director.country, ...(director.nationalities ?? [])].includes(CountryCode.US) && (
                    <Form.Item label="Tax Identification Number" tooltip="Tax identification number for your country of residence">
                      {director.personalTaxId}
                    </Form.Item>
                  )}
                  <Form.Item label="Is this person a domestic or foreign PEP?">{director?.isPEP ? "Yes" : "No"}</Form.Item>
                  <Form.Item label="Government Issued ID">
                    {Object.values(director.files)
                      .filter((f) => f.type === "sid")
                      .map((file) => {
                        return renderPersonsFile(director.id, file);
                      })}
                    {director.govIdDocNum}
                    {director.govIdType && director.govIdCountry && director.govIdState && (
                      <Text type="secondary">
                        {getIdTypes(director.govIdCountry).find((currency) => director.govIdType === currency.value)?.label || director.govIdType} from{" "}
                        {String(`${director.govIdState}, ${director.govIdCountry}`)}
                        {director.govIdIssuingAuthority && <div>Issuing Authority: {String(director.govIdIssuingAuthority)}</div>}
                        {director.govIdIssue && director.govIdExpires && (
                          <div>
                            Valid from <DateDisplay value={director.govIdIssue} time={false} icon={false} /> to{" "}
                            <DateDisplay value={director.govIdExpires} time={false} icon={false} />
                          </div>
                        )}
                      </Text>
                    )}
                  </Form.Item>
                </Collapse.Panel>
              ))}
            </Collapse>
          </Box>

          {!onboarding.noOwnership && (
            <>
              <Heading tag="h3">Beneficial Owners</Heading>
              <Box padding="none" flat>
                <Collapse accordion style={{ backgroundColor: "transparent" }} bordered={false}>
                  {owners.map((owner, index) => (
                    <Collapse.Panel
                      header={
                        <strong>
                          Beneficial Owner #{index + 1}: {owner.type === "individual" ? [owner.firstName, owner.lastName].join(" ") : owner.name}
                        </strong>
                      }
                      extra={<Status type="primary">{owner.percentOfOwnership}% Shares</Status>}
                      key={owner.id}
                    >
                      {owner.type === "individual" && <Form.Item label="Full Name">{[owner.firstName, owner.lastName].join(" ")}</Form.Item>}
                      {owner.type === "business" && <Form.Item label="Business Name">{owner.name}</Form.Item>}
                      {owner.type === "individual" && <Form.Item label="Position/Title">{owner.title}</Form.Item>}

                      {owner.type === "individual" && (
                        <Form.Item label="Residential Address">
                          {stringifyAddress({
                            address: owner.address,
                            city: owner.city,
                            region: owner.region,
                            zip: owner.zip,
                            country: owner.country,
                          })}
                        </Form.Item>
                      )}

                      {owner.type === "business" && (
                        <Form.Item label="Business Registration Country">
                          <Flag code={owner.country} />
                        </Form.Item>
                      )}

                      {owner.type === "individual" && (
                        <>
                          <Form.Item label="Email Address">{owner.email}</Form.Item>
                          <Form.Item label="Nationalities">
                            <Space direction="column" align="start">
                              {owner.nationalities
                                ?.filter((v) => v)
                                .map((code) => (
                                  <Flag key={code} code={code} />
                                ))}
                            </Space>
                          </Form.Item>
                          {[owner.country, ...(owner.nationalities ?? [])].includes(CountryCode.US) && (
                            <Form.Item label="SSN or ITIN" tooltip="Social Security Number or Individual Tax Identification Number">
                              {owner.personalTaxId}
                            </Form.Item>
                          )}
                          {![owner.country, ...(owner.nationalities ?? [])].includes(CountryCode.US) && (
                            <Form.Item label="Tax Identification Number" tooltip="Tax identification number for your country of residence">
                              {owner.personalTaxId}
                            </Form.Item>
                          )}
                          <Form.Item label="Is this person a domestic or foreign PEP?">{owner?.isPEP ? "Yes" : "No"}</Form.Item>
                          <Form.Item label="Government Issued ID">
                            {Object.values(owner.files)
                              .filter((f) => f.type === "sid")
                              .map((file) => {
                                return renderPersonsFile(owner.id, file);
                              })}
                            {owner.govIdDocNum}
                            {owner.govIdType && owner.govIdCountry && owner.govIdState && (
                              <Text type="secondary">
                                {getIdTypes(owner.govIdCountry).find((currency) => owner.govIdType === currency.value)?.label || owner.govIdType} from{" "}
                                {String(`${owner.govIdState}, ${owner.govIdCountry}`)}
                                {owner.govIdIssuingAuthority && <div>Issuing Authority: {String(owner.govIdIssuingAuthority) || "N/A"}</div>}
                                {owner.govIdIssue && owner.govIdExpires && (
                                  <div>
                                    Valid from <DateDisplay value={owner.govIdIssue} time={false} icon={false} /> to{" "}
                                    <DateDisplay value={owner.govIdExpires} time={false} icon={false} />
                                  </div>
                                )}
                              </Text>
                            )}
                          </Form.Item>
                        </>
                      )}

                      <Form.Item label="Shareholding Amount (%)">{owner.percentOfOwnership || 0}%</Form.Item>
                      <Form.Item label="Ownership Type">
                        {owner.ownershipType === "direct" && "Direct Shares"}
                        {owner.ownershipType === "indirect" && "Indirect Shares"}
                      </Form.Item>
                    </Collapse.Panel>
                  ))}
                </Collapse>
              </Box>
            </>
          )}

          <Divider transparent margin="medium" />
          {onboarding?.bankOnboardingCompletedAt ? (
            <Button size="large" type="primary" htmlType="submit" disabled block icon={<Icon.Status type="success" />}>
              Form Submitted
            </Button>
          ) : (
            <>
              {canComplete && (
                <Form.Item
                  name="terms"
                  className={styledErrors}
                  valuePropName="checked"
                  rules={[
                    {
                      async validator(rule, checked) {
                        if (!directors.length || directors.some((person) => !isPersonComplete(person, "directors"))) {
                          throw "One or more signing officers are incomplete";
                        }

                        if (!onboarding.noOwnership && (!owners.length || owners.some((person) => !isPersonComplete(person, "owners")))) {
                          throw "One or more beneficial owners are incomplete";
                        }

                        if (!getShareRegisters(onboarding).length) {
                          throw "You must upload a shareholder register";
                        }

                        if (!checked) {
                          throw "You must confirm and agree to the terms above";
                        }
                      },
                    },
                  ]}
                >
                  <Checkbox>
                    I confirm that I have the legal authority to create an account on behalf of the Business and that the information I have given is valid and
                    truthful.
                    <br />I also confirm I agree to the{" "}
                    <ExternalLink href="https://www.trolley.com/legal-agreements">Trolley Terms of Service and Privacy Policy</ExternalLink>.
                  </Checkbox>
                </Form.Item>
              )}
              {onboarding &&
                onboarding.businessCountry === CountryCode.US &&
                onboarding.expectedPayoutCountries.every((code) => code === CountryCode.US) &&
                canComplete && (
                  <Form.Item
                    name="businessAgreements"
                    className={styledErrors}
                    valuePropName="checked"
                    rules={[
                      {
                        async validator(rule, checked) {
                          if (!directors.length || directors.some((person) => !isPersonComplete(person, "directors"))) {
                            throw "One or more signing officers are incomplete";
                          }

                          if (!onboarding.noOwnership && (!owners.length || owners.some((person) => !isPersonComplete(person, "owners")))) {
                            throw "One or more beneficial owners are incomplete";
                          }

                          if (!getShareRegisters(onboarding).length) {
                            throw "You must upload a shareholder register";
                          }

                          if (!checked) {
                            throw "You must confirm and agree to the terms above";
                          }
                        },
                      },
                    ]}
                  >
                    <Checkbox>
                      I agree to the terms outlined on the
                      <ExternalLink href="https://go.trolley.com/business-deposit-account-agreement"> Business Deposit Account Agreement</ExternalLink> from
                      Trolley’s Bank Partner, Cross River Bank.
                    </Checkbox>
                  </Form.Item>
                )}
              <Divider transparent margin="medium" />

              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={onboardingStatus === BaseStatus.LOADING}
                disabled={!canComplete}
                block
                tooltipProps={
                  !accessSigningOfficer
                    ? { title: "Only Signing Officers can submit this form" }
                    : !isUserActive
                    ? { title: "Please confirm your email address in order to submit the form" }
                    : undefined
                }
              >
                Submit Bank Transfer Activation
              </Button>
            </>
          )}
        </Form>
      )}
    </OnboardingSection>
  );
}

const useStyledErrors = createUseStyle(({ theme }) =>
  styled`
    .${theme.prefixCls}-form-item-explain-error{
      margin-top: 16px;
      font-size: ${theme.fontSizeLG}px;
      text-align: center;
    }
`(),
);
