import { POST } from "services/request";
import { Dac7EoyTaxExportGUID, EndOfYear, SerializerMetaBlock, SupportedTaxRegions } from "@trolley/common-frontend";
import { OpCode, standardDispatch } from "store/dispatcher";
import store from "store";
import { computeID, isLoaded } from "./actionUtils";

export enum Dac7EoyExportStatusMerchant {
  PROCESSING = "processing",
  READY_TO_FILE = "ready to file",
  FILED = "filed",
  ERROR = "error",
}

export interface Dac7EoyTaxMarkFiledQuery {
  guid: Dac7EoyTaxExportGUID;
}

export async function markDac7ExportFiled(query: Dac7EoyTaxMarkFiledQuery) {
  await POST<{
    dac7EoyTaxExport: {
      exportGuid: string;
      recipientEoyTaxGuids: string[];
      taxYear: number;
      type: string;
      count: number;
      sandbox: boolean;
      status: string;
      s3XmlUri: string;
    };
  }>("/v1/tax-year/dac7-eoy/filed", {
    query,
  });
}

export interface Dac7EoyTaxFilingQuery {
  taxYear?: number;
  reportToRegion?: SupportedTaxRegions;
}

export async function generateDac7XmlFiles(query: Dac7EoyTaxFilingQuery) {
  await POST<{
    dac7EoyTaxExport: {
      exportGuid: string;
      recipientEoyTaxGuids: string[];
      taxYear: number;
      type: string;
      count: number;
      sandbox: boolean;
      status: string;
      s3XmlUri: string;
    };
  }>("/v1/tax-year/dac7-eoy/filing", {
    query,
  });
}

export interface Dac7EoyTaxExport extends EndOfYear.Dac7EoyTaxExport {
  s3CsvUri?: string;
}

export type Dac7EoyTaxExportsQuery = {
  taxYear: number;
  reportToRegion?: SupportedTaxRegions[];
};

export function loadDac7EoyTaxExports(query: Dac7EoyTaxExportsQuery, force?: boolean) {
  const { dac7EoyTaxExports } = store.getState();
  const id = computeID(query);

  if (force || !isLoaded(dac7EoyTaxExports.fetchStatus[id])) {
    standardDispatch(OpCode.LOADING, "dac7EoyTaxExports", { id });
    POST<{ dac7EoyTaxExports: Dac7EoyTaxExport[]; meta: SerializerMetaBlock }>("/v1/tax-year/dac7-eoy/export/search", {
      query,
    })
      .then(({ body }) => {
        standardDispatch(OpCode.DATA, "dac7EoyTaxExports", {
          id,
          data: {
            records: body.dac7EoyTaxExports,
            meta: body.meta,
          },
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "dac7EoyTaxExports", { id, errors });
      });
  }

  return id;
}
